import { useMemo } from 'react';
import { OrganizationTypes, NastRoles, TMCRoles, VendorRoles } from 'features/account-settings';
import { RoleAndPermissionsRadio } from 'features/account-settings';

export const useDisplayRolesForOrgType = (organization, role, handleRoleUpdate) => {
    const rolesToDisplay = useMemo(() => {
      switch (organization?.type) {
        case OrganizationTypes.CARRIER:
          return NastRoles;
        case OrganizationTypes.TMC:
          return TMCRoles;
        case OrganizationTypes.VENDOR:
          return VendorRoles;
        default:
          return [];
      }
    }, [organization]);
  
    const roleElements = useMemo(() => {
      return Object.values(rolesToDisplay).map((x) => (
        <RoleAndPermissionsRadio
          role={x}
          handleChange={handleRoleUpdate}
          isChecked={role === x}
          key={`radio-button-${x}`}
        />
      ));
    }, [rolesToDisplay, role, handleRoleUpdate]);
  
    return roleElements;
  };