import { useReactToPrint } from 'react-to-print';
import { MouseEvent, useId, useRef } from 'react';

import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { GenericAccordion } from 'shared/components/accordion/generic-accordion.component';
import { Button } from 'shared/components/elements/elements.components';
import { Checkbox } from 'shared/components/form-fields/fields.component';
import { useTranslation } from 'react-i18next';
import '../styles/agreement-option.component.scss';

export const QUICKPAY_USER_AGREEDMENT_MODAL = 'QUICKPAY_USER_AGREEDMENT_MODAL';

export interface Props {
  onSubmit?: (res: any) => any;
  onError?: (err: any) => any;
  onCancel: (e: MouseEvent<HTMLButtonElement>) => any;
}

export interface AgreementOptionProps {
  agreementName: ResourceKey;
  agreementField: NavCarrierFormControl;
  agreementContent: JSX.Element;
}

export const AgreementOption = ({ agreementName, agreementField, agreementContent }: AgreementOptionProps) => {
  const checkboxId = useId();
  const componentRef = useRef(null);
  const onPrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { t } = useTranslation();

  const actionButtons = (
    <div className="action-buttons-container" onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
      <Button btnLink onClick={onPrint} resource="PRINT_AGREEMENT" />
      <div>
        <Checkbox id={checkboxId} field={agreementField} />
        <label htmlFor={checkboxId}>
          {t("I_AGREE")}
        </label>
      </div>
    </div>
  );

  return (
    <div className="agree-option-container">
      <GenericAccordion
        drawerHidden
        header={({ isVisible, headerIcons }) => (
          <>
            <div>
              {headerIcons}
              <div className="agreement-name">
                {t(agreementName)}
              </div>
            </div>
            {!isVisible && actionButtons}
          </>
        )}
      >
        <section className="content">
          <div className="agreement-content" ref={componentRef}>
            {agreementContent}
          </div>
          {actionButtons}
        </section>
      </GenericAccordion>
    </div>
  );
};
