import React from 'react';
import { useCallback } from 'react';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';

import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { useToastManager } from 'shared/components/toast/use-toasts.hook';
import { getErrorMessage } from 'app/util/util';
import { useTranslation } from 'react-i18next';
import { useSubscription } from 'app/hooks/use-subscription.hook';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { UserRepository } from 'app/repositories/user.repository';
import { useCarrierCode } from 'app/hooks/store/use-carrier-code.hook';
import { emailPattern } from 'app/forms/control/form-validators';

export const useValidateEmailAddress = (form: NavCarrierFormGroup, setLoading: (loading: boolean) => any) => {
  const toasts = useToastManager();
  const carrierCode = useCarrierCode();
  const userRepo = useRepository(UserRepository);
  const showLoader = useCallback(() => setLoading(true), [setLoading]);
  const hideLoader = useCallback(() => setLoading(false), [setLoading]);
  const { t } = useTranslation();

  const onEmailValidationError = useCallback((err) => {
    form.setPendingValidation(true);
    form.validate();
  }, [form]);

  const onEmailValidationSuccess = useCallback((res: RegistrationValidationJSON) => {
    if (!res.isValid) {
      form.setServerErrors([{
        failureReason: res.reason,
        propertyName: null,
        propertyPath: null
      }]);
      form.setPendingValidation(true);
      form.validate();
    }
  }, [form]);

  const handleAjaxError = useCallback((err: AjaxError) => {
    toasts.error([
      t("CANNOT_PROCESS_YOUR_REQUEST_AT_THIS_TIME_PLEASE_TR"),
      '. ',
      getErrorMessage(err)
    ]);
    return Observable.empty();
  }, [toasts]);

  const validateEmailAddress = useCallback((email) => {
    showLoader();
    return userRepo.validateEmail(carrierCode, email)
      .catch(handleAjaxError)
      .finally(hideLoader);
  }, [userRepo, carrierCode, showLoader, hideLoader, handleAjaxError]);

  useSubscription(
    form.get('email').valueChanges
      .debounceTime(300)
      .do(() => form.clearServerErrors())
      .filter(email => Boolean(email) && Boolean(carrierCode)) // filter out empty field
      .filter(email => emailPattern.test(email)) // filter out invalid email addresses
      .switchMap(validateEmailAddress),
    onEmailValidationSuccess,
    [form, carrierCode, validateEmailAddress],
    onEmailValidationError
  );

};
