import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import * as Styles from 'features/account-settings/styles';

export enum AccountSettingsTabs {
	MANAGE_USERS = "manage-users",
	PROFILE_SETTINGS = "profile-settings",
	FINANCIAL_SETTINGS = "financial-settings",
	NOTIFICATION_SETTINGS = "notification-settings",
    MANAGE_DRIVERS = "manage-drivers"
}

export enum AccountSettingsTabViews {
	SIDEBAR = "sidebar",
	TABS = "tabs"
}

export interface NavigationTabsProps {
	settingsTab: AccountSettingsTabs;
	setSettingsTab: Dispatch<SetStateAction<AccountSettingsTabs>>;
	view: AccountSettingsTabViews;
    isAdmin: Boolean;
    canViewFinancialSettings: Boolean;
}

export const NavigationTabs = ({ settingsTab, setSettingsTab, view, isAdmin, canViewFinancialSettings }: NavigationTabsProps) => {
  const { t } = useTranslation();
  const { webPreferencesNotificationsBraze, enableUserManagement } = useFlags();
  const user = useStoredUser();

  const isManageUsers = settingsTab === AccountSettingsTabs.MANAGE_USERS;
  const isProfileSettings = settingsTab === AccountSettingsTabs.PROFILE_SETTINGS;
  const isFinancialSettings = settingsTab === AccountSettingsTabs.FINANCIAL_SETTINGS;
  const isNotificationSettings = settingsTab === AccountSettingsTabs.NOTIFICATION_SETTINGS;
  const isManageDrivers = settingsTab === AccountSettingsTabs.MANAGE_DRIVERS;

  const shouldShowNotificationSettings = webPreferencesNotificationsBraze && !user?.isEuropean();
  const shouldShowManageUsers = enableUserManagement && isAdmin;

  return (
    <>
      {view === AccountSettingsTabViews.SIDEBAR &&
        <Box sx={Styles.SidebarContainer} data-testid='sidebar-container'>
          <Typography variant="h3">
            {t('ACCOUNT_SETTINGS')}
          </Typography>
          <Stack direction="column" spacing={1} sx={{ paddingTop: '16px' }}>
            {shouldShowManageUsers &&
              <Button
                sx={isManageUsers ? Styles.SelectedSidebarButton : Styles.SidebarButton}
                variant="text"
                onClick={() => setSettingsTab(AccountSettingsTabs.MANAGE_USERS)}
                data-testid='manage-users-btn'
              >
                <Typography variant="subtitle200" sx={isManageUsers ? Styles.SelectedSidebarText : {}}>
                  {t('MANAGE_USERS')}
                </Typography>
              </Button>
            }
            <Button
              sx={isProfileSettings ? Styles.SelectedSidebarButton : Styles.SidebarButton}
              variant="text"
              onClick={() => setSettingsTab(AccountSettingsTabs.PROFILE_SETTINGS)}
              data-testid='profile-settings-btn'
            >
              <Typography variant="subtitle200" sx={isProfileSettings ? Styles.SelectedSidebarText : {}}>
                {t('PROFILE_SETTINGS')}
              </Typography>
            </Button>
            {canViewFinancialSettings &&
              <Button
                sx={isFinancialSettings ? Styles.SelectedSidebarButton : Styles.SidebarButton}
                variant="text"
                onClick={() => setSettingsTab(AccountSettingsTabs.FINANCIAL_SETTINGS)}
                data-testid='financial-settings-btn'
              >
                <Typography variant="subtitle200" sx={isFinancialSettings ? Styles.SelectedSidebarText : {}}>
                  {t('FINANCIAL_SETTINGS')}
                </Typography>
              </Button>
            }
            {shouldShowNotificationSettings &&
              <Button
                sx={isNotificationSettings ? Styles.SelectedSidebarButton : Styles.SidebarButton}
                variant="text"
                onClick={() => setSettingsTab(AccountSettingsTabs.NOTIFICATION_SETTINGS)}
                data-testid='notification-settings-btn'
              >
                <Typography variant="subtitle200" sx={isNotificationSettings ? Styles.SelectedSidebarText : {}}>
                  {t('NOTIFICATION_SETTINGS')}
                </Typography>
              </Button>
            }
            {!enableUserManagement && 
              <Button
                sx={isManageDrivers ? Styles.SelectedSidebarButton : Styles.SidebarButton}
                variant="text"
                onClick={() => setSettingsTab(AccountSettingsTabs.MANAGE_DRIVERS)}
                data-testid='manage-drivers-btn'
              >
                <Typography variant="subtitle200" sx={isNotificationSettings ? Styles.SelectedSidebarText : {}}>
                  {t('MANAGE_DRIVERS')}
                </Typography>
              </Button>
            }
          </Stack>
        </Box>
      }
      {view === AccountSettingsTabViews.TABS &&
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={settingsTab}
            sx={Styles.Tabs}
            onChange={(_: React.SyntheticEvent, newValue: AccountSettingsTabs) => setSettingsTab(newValue)}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label='settings tabs'
            data-testid='tabs-container'
          >
            {shouldShowManageUsers &&
              <Tab
                value={AccountSettingsTabs.MANAGE_USERS}
                label={t('MANAGE_USERS')}
                sx={isManageUsers ? Styles.ActiveTab : Styles.InactiveTab}
                data-testid='manage-users-tab'
              />
            }
            <Tab
              value={AccountSettingsTabs.PROFILE_SETTINGS}
              label={t('PROFILE_SETTINGS')}
              sx={isProfileSettings ? Styles.ActiveTab : Styles.InactiveTab}
              data-testid='profile-settings-tab'
            />
            <Tab
              value={AccountSettingsTabs.FINANCIAL_SETTINGS}
              label={t('FINANCIAL_SETTINGS')}
              sx={isFinancialSettings ? Styles.ActiveTab : Styles.InactiveTab}
              data-testid='financial-settings-tab'
            />
            {shouldShowNotificationSettings &&
              <Tab
                value={AccountSettingsTabs.NOTIFICATION_SETTINGS}
                label={t('NOTIFICATION_SETTINGS')}
                sx={isNotificationSettings ? Styles.ActiveTab : Styles.InactiveTab}
                data-testid='notification-settings-tab'
              />
            }
            {!enableUserManagement &&
              <Tab
                value={AccountSettingsTabs.MANAGE_DRIVERS}
                label={t('MANAGE_DRIVERS')}
                sx={isManageDrivers ? Styles.ActiveTab : Styles.InactiveTab}
                data-testid='manage-drivers-tab'
              />
            }
          </Tabs>
        </Box>
      }
    </>
  );
}