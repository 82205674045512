import React from 'react';
import { CircularProgress } from '@mui/material';
import { Button } from 'shared/components/elements/button.component'
import { useTranslation } from 'react-i18next';

interface Props {
    hide: () => void;
    disabled: boolean;
    isProcessing: boolean;
    onSubmit: () => void;
}

export const PaymentOptionButtonGroup = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div>
            <Button btnDefault resource="CANCEL" onClick={props.hide} />
            <Button btnPrimary disabled={props.disabled || props.isProcessing} onClick={props.onSubmit} >
                {props.isProcessing && <span><CircularProgress thickness={5} color="primary" size={20} /></span>} <span>{t("SUBMIT")}</span>
            </Button>
        </div>
    );
}
