import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import * as Styles from 'features/account-settings/manage-users/styles';

interface UserManagerSelectionProps {
  isAdmin: Boolean;
  setIsAdmin: (value: Boolean) => void;
}

export const UserManagerSelection = ({ isAdmin, setIsAdmin }: UserManagerSelectionProps) => {
  
    const { t } = useTranslation();

    return (
    <Box sx={Styles.UserSwitchContainer}>
      <Switch
        sx={Styles.SwitchStyles}
        onChange={() => setIsAdmin(!isAdmin)}
        checked={Boolean(isAdmin)}
        data-testid="user-manager-switch"
      />
      <Box sx={Styles.SwitchText}>
        <Typography variant="h4">{t('USER_MANAGER')}</Typography>
        <Typography sx={Styles.SwitchTypographyDescription}>
          {t('CAN_MANAGE_USERS')}
        </Typography>
      </Box>
    </Box>
  );
};
