import React from 'react';
import { MouseEvent, useMemo, useCallback } from 'react';
import classNames from 'classnames';

import { useModal } from 'app/hooks/use-modal.hook';
import { Button } from 'shared/components/elements/elements.components';
import { Modal } from 'shared/components/modal/modal.component';
import { Input } from 'shared/components/form-fields/fields.component';
import { FormError } from 'shared/components/formatters/form-error.formatter';
import { useUpdateOnFormChanges } from 'app/hooks/forms/use-update-on-form-changes.hooks';
import { DriverModalForm } from 'features/account-settings';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { Contact } from 'shared/models/contacts/contact.model';
import { useAjaxRequest } from 'app/hooks/ajax/use-ajax-request.hook';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { CarriersRepository } from 'app/repositories/carriers.repository';
import { useCarrierCode } from 'app/hooks/store/use-carrier-code.hook';
import { useOnFormSubmit } from 'app/hooks/forms/use-on-form-submit.hook';
import { useTranslation } from 'react-i18next';
import { useToastManager } from 'shared/components/toast/use-toasts.hook';

import '../styles/driver-modal.component.scss';
import { PhoneNumberInput } from 'shared/components/phone-number-input/phone-number.component';

export const DRIVER_MODAL = 'driver-modal';

interface DriverModalContext {
  driver: any;
}

interface Props {
  onSuccess: () => any;
}

export const DriverModal: React.FC<Props> = ({onSuccess}) => {
  const [, hide, {driver}] = useModal<DriverModalContext>(DRIVER_MODAL);
  const carrierCode = useCarrierCode();
  const carriersRepo = useRepository(CarriersRepository);
  const form = useMemo(() => new DriverModalForm(driver), [driver]);
  const onBlur = useCallback((field: NavCarrierFormControl) => () => field.touch(), []);
  const { t } = useTranslation();
  const onPhoneChange = (value: string) => {
    const phoneNumberField = form.get('defaultPhone.phoneNumber');
    phoneNumberField.setValue(value);
  };
  const toasts = useToastManager();
  useUpdateOnFormChanges(form);

  const submitSuccess = useCallback(() => {
    hide();
    driver?.id
      ? toasts.success([t("YOUR_CHANGES_HAVE_BEEN_SAVED")])
      : toasts.success([t("DRIVER_HAS_BEEN_ADDED")]);
    onSuccess();
  }, [toasts, driver]);
  const submitError = useCallback(() => {
    hide();
    driver?.id
    ? toasts.error([t("UNABLE_TO_SAVE_CHANGES_AT_THIS_TIME")])
    : toasts.error([t("UNABLE_TO_ADD_DRIVER_AT_THIS_TIME")]);
  }, [toasts]);

  const saveContact = useAjaxRequest((contact: Contact) => {
      return(contact?.id) ? carriersRepo.updateContact(carrierCode, contact) : carriersRepo.createContact(carrierCode, contact);
    },
    submitSuccess,
    [carrierCode, carriersRepo],
    submitError
  );

  const formSubmit = useOnFormSubmit(form, f => f.getDriverAsContact(), saveContact);

  const onSubmit = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formSubmit();
  }, [form]);

  return (
    <Modal
      name={DRIVER_MODAL}
      isForm
      header={driver?.id ? 'EDIT_DRIVER' : 'ADD_A_DRIVER'}
      children={() =>
        <React.Fragment>
          <div className="flex flex-row row-space-outter-sm">
            <Input
              label="FIRST_NAME"
              id="js-driver-first-name"
              required
              className="full-width"
              field={form.get('firstName')}
              renderErrors={field =>
                <FormError display={field.hasError('required')} resource="FIRST_NAME_REQUIRED"/>
              }
            />
            <Input
              label="LAST_NAME"
              id="js-driver-last-name"
              required
              className="full-width"
              field={form.get('lastName')}
              renderErrors={field =>
                <FormError display={field.hasError('required')} resource="LAST_NAME_REQUIRED"/>
              }
            />
          </div>
          <div className="flex flex-row row-space-outter-sm">
            <div className={classNames('js-field-driver-phoneNumber', 'form-group', 'full-width', {'has-error': form.get('defaultPhone.phoneNumber').hasErrors()})}>
              <PhoneNumberInput phoneValue={form.get('defaultPhone.phoneNumber').value} onPhoneChange={onPhoneChange} required withLabels onBlur={onBlur(form.get('defaultPhone.phoneNumber'))} />
              <FormError display={form.get('defaultPhone.phoneNumber').hasError('required')} resource="PHONE_REQUIRED"/>
              <FormError display={form.get('defaultPhone.phoneNumber').hasError('validPhone')} resource="PLEASE_ENTER_A_VALID_PHONE_NUMBER"/>
            </div>

            <Input
              label="EMAIL_ADDRESS"
              id="js-driver-email-address"
              required
              className={'full-width'}
              field={form.get('defaultEmail.emailAddress')}
              renderErrors={field =>
                <React.Fragment>
                  <FormError display={field.hasError('required')} resource="EMAIL_REQUIRED"/>
                  <FormError display={!field.hasError('required') && field.hasError('email')} resource="PLEASE_ENTER_A_VALID_EMAIL_ADDRESS"/>
                  <FormError display={!field.hasError('email') && field.hasError('maxLength')} resource="EMAIL_ADDRESS_CANNOT_BE_GREATER_THAN_50_CHARACTERS"/>
                </React.Fragment>
              }
            />
          </div>
        </React.Fragment>
      }
      footer={() =>
        <React.Fragment>
          <Button
            btnLink
            resource="CANCEL"
            onClick={hide}
            id="js-add-driver-cancel-modal-btn"
            track
          />
          <Button
            btnPrimary
            type="submit"
            onClick={onSubmit}
            id="js-add-driver-submit-modal-btn"
            track
            resource={driver?.id ? 'SAVE_CHANGES' : 'ADD_A_DRIVER'}/>
        </React.Fragment>
      }
    />
  );
};
