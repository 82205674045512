import { lazy } from 'react';

export * from './components';
export * from './financial-settings';
export * from './manage-users';
export * from './notification-settings';
export * from './profile-settings';
export * from './manage-drivers';

export const LazyAccountSettingsPage = lazy(() =>
	import(/* webpackChunkName: "account-settings", webpackPrefetch: true */ 'features/account-settings/components/AccountSettings').then(
		({ AccountSettings }) => ({
			default: AccountSettings,
		})
	)
);