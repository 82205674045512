import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PhoneInput, { Country } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as Styles from 'features/account-settings/manage-users/styles';

interface BaseFieldProps {
 value: string;
 onChange: (v: string) => void;
}

interface FilterTextFieldProps extends BaseFieldProps {
 placeholder: string;
 errorMessage: string;
}

export const FilterTextField = ({ value, placeholder, onChange, errorMessage }: FilterTextFieldProps) => {
 const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(e.target.value);

 return (
  <TextField
   value={value}
   onChange={handleOnChange}
   placeholder={placeholder}
   fullWidth
   helperText={errorMessage}
   error={!!errorMessage}
   InputProps={{
    sx: Styles.TextField,
   }}
  />
 );
};

interface FilterPhoneFieldProps extends BaseFieldProps {
 errorMessage: string;
}

export const FilterPhoneField = ({ value, onChange, errorMessage }: FilterPhoneFieldProps) => {
 const [isValidState, setIsValidState] = useState(true);

 const onPhoneNumberChange = useCallback(
  (v: any) => {
   onChange(v);
  },
  [onChange]
 );

 const onCountryChange = useCallback(
  (country: Country) => {
   setIsValidState(Boolean(country));
  },
  [setIsValidState]
 );

 const onFocus = useCallback(
  (e: any) => {
   if (!isValidState) {
    onChange('');
   }
  },
  [onChange, isValidState]
 );

 return (
  <Box sx={Styles.PhoneInput}>
   <PhoneInput
    id={errorMessage ? 'phone-number-error' : 'filter-phone-input'}
    data-testid="filter-phone-input"
    defaultCountry="US"
    value={value}
    onChange={onPhoneNumberChange}
    international
    limitMaxLength
    countryCallingCodeEditable={false}
    onCountryChange={onCountryChange}
    onFocus={onFocus}
   />
   <p className="helperText">{errorMessage}</p>
  </Box>
 );
};

interface FilterStatusFieldProps extends BaseFieldProps {
 options: MembershipStatusOption[];
}

export const FilterStatusField = ({ value, options = [], onChange }: FilterStatusFieldProps) => {
 const handleOnChange = (e: any) => onChange(e.target.value);
 const defaultOption = options[0];

 useEffect(() => {
  if (!value) {
   onChange(defaultOption.key);
  }
 }, [value, defaultOption, onChange]);

 return (
  <Select fullWidth value={value} defaultValue={defaultOption.key} onChange={handleOnChange} sx={Styles.TextField}>
   {options.map(o => (
    <MenuItem key={o.key} value={o.key}>
     <Typography variant="h4">{o.displayValue}</Typography>
    </MenuItem>
   ))}
  </Select>
 );
};
