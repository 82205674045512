import { Dispatch, SetStateAction } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import { ManageUsersViews } from 'features/account-settings';
import { useTranslation } from 'react-i18next';
import * as Styles from 'features/account-settings/manage-users/styles';

export const BreadcrumbsComponent = ({ setView, view }: { setView: Dispatch<SetStateAction<symbol>>, view: string }) => {
    const { t } = useTranslation();
    return (
        <Breadcrumbs>
            <Button
                sx={Styles.BreadCrumbButton}
                variant="text"
                onClick={() => setView(ManageUsersViews.MANAGE_USERS)}
                data-testid="manage-users-breadcrumb"
            >
                {t('MANAGE_USERS')}
            </Button>
            <Button
                disabled
                sx={Styles.BreadCrumbButton}
                variant="text"
            >
                {view}
            </Button>
        </Breadcrumbs>
    )
}
