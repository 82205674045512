import { SxProps } from '@mui/material/styles';

export const ContainerStyle: SxProps = {
  maxWidth: '1102px',
  p: '24px 40px',
  mt: '10px',
  bgcolor: '#FFFFFF',
};

export const AccordionStyle: SxProps = {
  boxShadow: 'none',
  width: '100%',
  borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '36px',
};

export const AccordionSummaryBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '6px',
};

export const HeaderStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '8px',
};

export const StatusStyle = (color: string): SxProps => ({
  display: 'flex',
  marginRight: '28px',
  marginTop: '8px',
  color,
});

export const RoleBoxStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '4px',
};

export const AccordionPaddingStyle: SxProps = {
  padding: {
    md: '12px 48px',
  },
};

export const MarginTopStyle: SxProps = {
  marginTop: {
    xs: '16px',
    md: '0px',
  },
  padding: {
    md: '12px 48px',
  },
};

export const UserManagerBoxStyle: SxProps = {
  padding: {
    md: '12px 48px',
  },
};

export const DeleteContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'flex-start',
  padding: '12px 48px',
};

export const RemoveButtonStyle: SxProps = {
  width: '240px',
  alignSelf: 'flex-end',
  marginTop: '14px',
  marginBottom: '14px',
};

export const RoleChipStyle: SxProps = {
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#F6F7F8',
    marginLeft: '16px',
};