export enum CommunicationChannelType
{
    // Represents an unknown communication channel.
    None = 0,
    // Represents SMS communication channel.
    SMS = 1,
    // Represents Email communication channel.
    Email = 2,
    // Represents Push communication channel.
    Push = 3,
    // Represents InApp communication channel.
    InApp = 4,
}