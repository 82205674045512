import React from 'react';
import { useCallback } from 'react';
import { Observable } from 'rxjs/Observable';

import { useModal } from 'app/hooks/use-modal.hook';
import { Button } from 'shared/components/elements/elements.components';
import { Modal } from 'shared/components/modal/modal.component';
import { Contact } from 'shared/models/contacts/contact.model';
import { useAjaxRequest } from 'app/hooks/ajax/use-ajax-request.hook';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { CarriersRepository } from 'app/repositories/carriers.repository';
import { useCarrierCode } from 'app/hooks/store/use-carrier-code.hook';
import { useTranslation } from 'react-i18next';
import { useToastManager } from 'shared/components/toast/use-toasts.hook';

import '../styles/driver-modal.component.scss';

export const DELETE_DRIVER_MODAL = 'delete-driver-modal';

interface DeleteDriverModalContext {
  drivers: Contact[];
}

interface Props {
  onSuccess: () => any;
}

export const DeleteDriverModal: React.FC<Props> = ({onSuccess}) => {
  const [, hide, {drivers}] = useModal<DeleteDriverModalContext>(DELETE_DRIVER_MODAL);
  const carrierCode = useCarrierCode();
  const carriersRepo = useRepository(CarriersRepository);
  const toasts = useToastManager();
  const { t } = useTranslation();

  const submitSuccess = useCallback(() => {
    hide();
    drivers.length > 1
      ? toasts.success([t("THE_SELECTED_DRIVERS_HAVE_BEEN_DELETED")])
      : toasts.success([t("DRIVER_HAS_BEEN_DELETED")]);
    onSuccess();
  }, [toasts, drivers]);
  const submitError = useCallback(() => {
    hide();
    drivers.length > 1
    ? toasts.error([t("UNABLE_TO_DELETE_SELECTED_DRIVERS_AT_THIS_TIME")])
    : toasts.error([t("UNABLE_TO_DELETE_DRIVER_AT_THIS_TIME")]);
  } , [toasts, drivers]);

  const deleteAllDrivers = useAjaxRequest(() => {
      return Observable.forkJoin( drivers.map(oneDriver => carriersRepo.deleteContact(carrierCode, oneDriver)));
    },
    submitSuccess,
    [carrierCode, carriersRepo],
    submitError
  );

  if (drivers && drivers.length > 1) {
    return (
      <Modal
        name={DELETE_DRIVER_MODAL}
        header="DELETE_DRIVERS"
        children={() =>
          <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_DRIVERS")}</p>
        }
        footer={() =>
          <React.Fragment>
            <Button btnLink id="cancel-multiple-drivers-modal-btn" track resource="CANCEL" onClick={hide}/>
            <Button
              btnPrimary
              id="delete-multiple-drivers-modal-btn"
              track
              onClick={deleteAllDrivers}
              className="js-delete-driver"
              resource="DELETE_SELECTED"/>
          </React.Fragment>
        }
      />
    );
  }

  return (
    <Modal
      name={DELETE_DRIVER_MODAL}
      header="DELETE_DRIVER"
      children={() =>
        <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_DRIVER")}</p>
      }
      footer={() =>
        <React.Fragment>
          <Button btnLink id="cancel-single-driver-modal-btn" track resource="CANCEL" onClick={hide}/>
          <Button
            btnPrimary
            id="delete-single-driver-modal-btn"
            track
            onClick={deleteAllDrivers}
            className="js-delete-driver"
            resource="DELETE_DRIVER"/>
        </React.Fragment>
      }
    />
  );
};
