import { useModal } from '@app/hooks/use-modal.hook';
import { Button } from '@shared/components/elements/button.component';
import { Modal } from '@shared/components/modal/modal.component';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { history } from 'store';

import '../styles/preferences-prompt.component.scss';

export const PREFERENCES_CENTER_PROMPT_MODAL = 'preferences-center-prompt-modal';

export function PreferencesPrompt(props) {
  const { when, onOK, onCancel } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [showModal, hideModal] = useModal(PREFERENCES_CENTER_PROMPT_MODAL);
  const { t } = useTranslation();

  useEffect(() => {
    if (when) {
      history.block(prompt => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        showModal();
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        hideModal();
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
    hideModal();
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      name={PREFERENCES_CENTER_PROMPT_MODAL}
      header="YOU_HAVE_NOT_SAVED_YOUR_CHANGES"
      footer={[
        <Button btnDefault mobileResponsive resource="DISCARD_CHANGES" onClick={handleOK} key="discard-changes" />,
        <Button btnPrimary mobileResponsive resource="APPLY_CHANGES" onClick={handleCancel} key="save-changes" />,
      ]}
    >
      {t("ARE_YOU_SURE_YOU_WANT_TO_LEAVE_SETTING_PAGE")}
    </Modal>
  ) : null;
}
