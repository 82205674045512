import * as v from 'app/forms/control/form-validators';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { groupStructure } from 'app/forms/structure/form-group.structure';
import { Contact } from 'shared/models/contacts/contact.model';
import { PhoneType } from 'shared/enums/phone-type.enum';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

export class DriverModalForm extends NavCarrierFormGroup {
  constructor(driver) {
    super(groupStructure({
      firstName: fieldStructure(driver ? driver.firstName : '', { validators: [v.required] }),
      lastName: fieldStructure(driver ? driver.lastName : '', { validators: [v.required] }),
      defaultPhone: groupStructure({
        id: fieldStructure(driver?.defaultPhone ? driver.defaultPhone.id : 0, { validators: [v.required] }),
        phoneType: fieldStructure(driver?.defaultPhone ? driver.defaultPhone.phoneType : PhoneType.MobilePhone, { validators: [v.required] }),
        phoneNumber: fieldStructure(driver?.defaultPhone ? `+${driver?.defaultPhone.dialingCode}${driver?.defaultPhone.number}` : '', { validators: [v.required, v.create('validPhone', (phone: any) => {
          return !phone || isPossiblePhoneNumber(phone);
        })] }),
      }),
      defaultEmail: groupStructure({
        id: fieldStructure(driver?.defaultEmail ? driver.defaultEmail.id : 0, { validators: [v.required] }),
        emailAddress: fieldStructure(driver?.defaultEmail ? driver.defaultEmail.emailAddress : '', { validators: [v.required, v.email, v.maxLength(50)] })
      }),
      id: fieldStructure(driver ? driver.id : 0, { validators: [] }),
      icon: fieldStructure(driver ? driver.icon : '', { validators: [] }),
      contactSequenceNumber: fieldStructure(driver ? driver.contactSequenceNumber : 0, { validators: [] }),
      position: fieldStructure(driver ? driver.position : '', { validators: [] }),
      department: fieldStructure(driver ? driver.department : '', { validators: [] }),
    }));

    if (Boolean(driver?.id)) {
      this.get('firstName').disable();
      this.get('lastName').disable();
    }
  }

  getDriverAsContact(): Contact | undefined {
    const phoneNumber = parsePhoneNumber(this.get('defaultPhone.phoneNumber').value);
    const countryCallingCode = phoneNumber?.countryCallingCode || '';
    const nationalNumber = phoneNumber?.nationalNumber || '';
    const newDriver = new Contact(
      {
        firstName: this.get('firstName').value,
        lastName: this.get('lastName').value,
        defaultPhone: { id: this.get('defaultPhone.id').value, phoneType: this.get('defaultPhone.phoneType').value, dialingCode: countryCallingCode, number: nationalNumber },
        defaultEmail: { id: this.get('defaultEmail.id').value, emailAddress: this.get('defaultEmail.emailAddress').value },
        id: this.get('id').value,
        icon: this.get('icon').value,
        contactSequenceNumber: this.get('contactSequenceNumber').value,
        position: this.get('position').value,
        department: this.get('department').value
      }
    );

    return new Contact(newDriver as ContactJSON);
  }
}
