import { useAnalyticsTrack } from '@chr/react-analytics';
import { EVENT_TYPES } from '@features/analytics/constants';
import { useAnalyticsEventMetaData } from '@features/analytics/hooks/use-analytics-event-metadata.hook';
import { useCallback } from 'react';

const valueKey = {
	'carrier_advantage_program': 'notificationsCarrierAdvantageUpdates',
	'load_detail_update': 'notificationsLoadDetailUpdates',
	'booked_cancelled_loads': 'notificationsBookedCancelledLoads',
	'tracking_update': 'notificationsTrackingUpdates',
	'appointment_update': 'notificationsAppointments',
	'suggested_loads': 'notificationsSuggestedLoads',
}

export const useToggleNotificationEvent = () => {
	const track = useAnalyticsTrack();
	const eventMetaData = useAnalyticsEventMetaData();


	const toggleNotificationEvent = useCallback(preferences => {
		const categories = {};
		Object.keys(preferences).forEach((key) => {
			categories[valueKey[key]] = typeof preferences[key] == 'boolean' ? preferences[key].toString() : false;
		})

		track({
			event: EVENT_TYPES.NOTIFICATION_TOGGLE,
			...eventMetaData,
			...categories,
		} as any);
	}, []);

	return toggleNotificationEvent;
};
