import { SxProps } from '@mui/system';

export const UserSwitchContainer: SxProps = {
  display: 'flex',
  width: '100%',
  minHeight: '40px',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: '16px',
};

export const SwitchText: SxProps = {
  display: 'flex',
  padding: '8px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
};

export const SwitchStyles: SxProps = {
  '& .MuiSwitch-input': {
    position: 'absolute',
  },
};

export const SwitchTypographyDescription: SxProps = {
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
};
