import { useState } from 'react';

export const usePaginationV2 = (defaultPageNumber, defaultPageSize) => {
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [itemsPerPage, setItemsPerPage] = useState(defaultPageSize);

  return [
    pageNumber,
    itemsPerPage,
    setPageNumber,
    setItemsPerPage,
  ];
};

