import React from 'react';
import { useState, FunctionComponent, useMemo, MouseEvent  } from 'react';

import { Button } from 'shared/components/elements/elements.components';
import { Modal } from 'shared/components/modal/modal.component';
import { ChangeEmailForm, useValidateEmailAddress } from 'features/account-settings';
import { Input } from 'shared/components/form-fields/input.component';
import { FormError } from 'shared/components/formatters/form-error.formatter';
import { useForceRefreshToken } from 'app/hooks/use-force-refresh-token.hook';
import { useOnFormSubmit } from 'app/hooks/forms/use-on-form-submit.hook';

export const CHANGE_EMAIL_MODAL = 'change-email-address';

interface OwnProps {
  onSubmit: (email: string) => void;
  onCancel: (e: MouseEvent<HTMLButtonElement>) => any;
}

type Props = OwnProps;

export const ChangeEmailModal: FunctionComponent<Props> = ({onSubmit, onCancel}) =>  {
  const [loading, setLoading] = useState(false);
  const [refreshToken, forceRefresh] = useForceRefreshToken();
  const form = useMemo(() => new ChangeEmailForm(), [refreshToken]);

  useValidateEmailAddress(form, setLoading);

  const onFormSubmit = useOnFormSubmit(form, f => f.value.email, onSubmit);

  return (
    <form id="change-email-form" onSubmit={onFormSubmit}>
      <Modal
        name={CHANGE_EMAIL_MODAL}
        header="CHANGE_EMAIL_ADDRESS"
        onHide={forceRefresh}
        children={() =>
          !form
            ? null
            : <React.Fragment>
                <Input
                  label="NEW_EMAIL_ADDRESS"
                  id="change-email-modal_new-email-address"
                  className="js-new-email"
                  name="emailAddress"
                  type="text"
                  field={form.get('email')}
                  renderErrors={field =>
                    <React.Fragment>
                      <FormError resource="EMAIL_REQUIRED" display={field.hasError('required')}/>
                      <FormError resource="PLEASE_ENTER_A_VALID_EMAIL_ADDRESS" display={!field.hasError('required') && field.hasError('email')}/>
                      <FormError resource="EMAIL_ADDRESS_CANNOT_BE_GREATER_THAN_50_CHARACTERS" display={field.hasError('maxLength')}/>
                      <FormError resource="THE_EMAIL_ADDRESS_ENTERED_IS_ALREADY_IN_USE" display={field.hasError('apiError')}/>
                    </React.Fragment>
                  }
                />
                <Input
                  label="CONFIRM_NEW_EMAIL_ADDRESS"
                  id="change-email-modal_confirm-email-address"
                  className="js-confirm-email"
                  name="confirmEmailAddress"
                  type="text"
                  field={form.get('confirmEmail')}
                  renderErrors={field =>
                    <React.Fragment>
                      <FormError resource="PLEASE_CONFIRM_YOUR_EMAIL_ADDRESS" display={field.hasError('required')}/>
                      <FormError resource="EMAIL_ADDRESSES_DO_NOT_MATCH" display={!field.hasError('required') && field.hasError('mustMatch')}/>
                    </React.Fragment>
                  }
                />
              </React.Fragment>
        }
        footer={() =>
          <React.Fragment>
            <Button id="change-email-modal_cancel-button" btnLink onClick={onCancel} resource="CANCEL"/>
            <Button id="change-email-submit-button" type="submit" btnPrimary resource="SAVE" disabled={loading}/>
          </React.Fragment>
        }
      />
    </form>
  );
};
