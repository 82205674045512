import { useModal } from "app/hooks/use-modal.hook";
import { Button } from "shared/components/elements/button.component";
import { useModalState } from "shared/components/modal/hooks/use-modal-state.hook";
import { Modal } from "shared/components/modal/modal.component";
import { useTranslation } from 'react-i18next';
import "../styles/payment-option-dialog-modal.component.scss";

export const PAYMENT_OPTION_DIALOG_MODAL = "payment-option-dialog-modal";

export const PaymentOptionDialogModal = () => {
    const [, hide] = useModal(PAYMENT_OPTION_DIALOG_MODAL);
    const modalState = useModalState(PAYMENT_OPTION_DIALOG_MODAL);
    const { t } = useTranslation();
    const renderHeader = () => (
        <p className="payment-option-dialog-modal-heading-text" data-test="payment-option-dialog-modal-header">
            {t("WE_CAN_HELP_WITH_THAT!")}
        </p>
    );

    const renderBody = () => (
        <p className="payment-option-dialog-modal-body-text" data-test="payment-option-dialog-modal-body">
            {t("TO_MAKE_CHANGES_TO_YOUR_CURRENT_PAYMENT_SETTINGS")}
        </p>
    );

    const Footer = (
        <Button
            className="payment-option-dialog-modal-close-button"
            btnPrimary
            data-test="payment-option-dialog-modal-dismissal"
            onClick={hide}
        >
            {t("CLOSE")}
        </Button>
    );

    return (
        <Modal name={PAYMENT_OPTION_DIALOG_MODAL} header={renderHeader} footer={Footer} data-test="payment-option-dialog-modal">
            {modalState?.visible && renderBody()}
        </Modal>
    );
};
