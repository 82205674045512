import React from 'react';
import classNames from 'classnames';
import { FunctionComponent, useCallback } from 'react';

import { Translation } from 'shared/components/translation/translation.component';
import { useAjaxResponse } from 'app/hooks/ajax/use-ajax-response.hook';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';

interface OwnProps {
  timezone?: string;
  onChange: (timezone: string) => void;
}

type Props = OwnProps;

export const TimeZoneSelect: FunctionComponent<Props> = ({timezone, onChange}) => {
  const refDataRepo = useRepository(ReferenceDataRepository);
  const [timeZones] = useAjaxResponse(() => refDataRepo.getTimeZones(), [], {initialValue: []})
  const onTimeZoneChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => onChange(event.target.value), [onChange]);

  return (
    <div className={classNames('form-group', 'js-field-country')}>
      <label htmlFor="timezone" className="required">
        <Translation resource="TIMEZONE" />
      </label>
      <select
        value={timezone}
        onChange={onTimeZoneChange}
        className="select-time-zone form-control"
      >
        {timeZones && timeZones.map((item) =>
          <option value={item.id} key={item.id}>{item.name}</option>
        )}
      </select>
    </div>
  );
}
