import { emailPattern } from 'app/forms/control/form-validators';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export const isValidEmail = (email: any) => {
 if (!email || email.trim().length === 0) {
  return { valid: false, message: 'REQUIRED_EMAIL' };
 }

 if (!emailPattern.test(email)) {
  return { valid: false, message: 'INVALID_EMAIL' };
 }

 return { valid: true };
};

export const isValidName = (value: any) => {
 return { valid: Boolean(value), message: 'REQUIRED_NAME' };
};

export const isValidPhone = (value: any) => {
 if (!Boolean(value)) {
  return { valid: false, message: 'REQUIRED_PHONE_NUMBER' };
 }

 if (!isPossiblePhoneNumber(value)) {
  return { valid: false, message: 'INVALID_PHONE_NUMBER' };
 }

 return { valid: true };
};
