export const TablePaper = {
  width: '100%',
  overflow: 'hidden',
  p: 0,
  height: 'auto',
  boxShadow: 'none',
  borderRadius: '8px',
  border: '1px solid #E5E6E7',
};

export const TableHeading = {
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
};

export const TableComponent = { height: '100%' };

export const TableHead = { borderBottom: '2px solid #E5E6E7' };

export const TableRow = {
  borderTop: '2px rgba(0, 0, 0, 0.1) solid',
  '&:last-child': {
    borderBottom: '2px rgba(0, 0, 0, 0.1) solid',
  },
};

export const PaginationContainer = { padding: '8px 16px 0px' };

export const NoDataContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '50px',
  textAlign: 'center',
  gap: '8px',
};

export const TableCell = {
  fontWeight: '400',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  textAlign: 'left',
};

export const StatusIcon = {
  verticalAlign: 'middle',
};
