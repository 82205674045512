import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { AgreementOptionContext } from 'features/account-settings';

import './generic-accordion.component.scss';

interface Header {
  isVisible?: boolean;
  toggleVisibility?: () => void;
  headerIcons?: JSX.Element;
}

interface Props {
  drawerHidden?: boolean;
  header: (header: Header) => JSX.Element;
  children: ReactNode;
}

export const GenericAccordion: React.FC<Props> = ({ header, drawerHidden, children }) => {
  const [isVisible, setVisibility] = useState(!drawerHidden);
  const hide = useContext(AgreementOptionContext);

  const toggleVisibility = useCallback(() => {
    setVisibility(value => !value);
  }, []);

  useEffect(() => {
    setVisibility(false);
  }, [hide]);

  const headerIcons = (
    <>
      <span className="glyphicon glyphicon-menu-up" />
      <span className="glyphicon glyphicon-menu-down" />
    </>
  );

  return (
    <ErrorBoundary className="space-inner-top-md space-inner-bottom-md space-inner-left-md space-inner-right-md">
      <div className={'detail-accordion accordion-wrapper' + (isVisible ? ' is-expanded' : '')}>
        <div className="accordion-header" onClick={toggleVisibility}>
          {header({isVisible, toggleVisibility, headerIcons})}
        </div>
        <div className={`accordion-content ${!isVisible && 'hidden'}`}>{children}</div>
      </div>
    </ErrorBoundary>
  );
};
