import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForceUpdate } from 'app/hooks/use-force-update.hook';
import {
  AgreementOption,
  AgreementOptionContext,
  AgreementForm,
  quickPayLegalAgreements,
  PaymentOptionButtonGroup
} from 'features/account-settings';
import { useModalState } from 'shared/components/modal/hooks/use-modal-state.hook';
import { Modal } from 'shared/components/modal/modal.component';
import { LegalAgreementType } from 'shared/enums/legal-agreement/legal-agreement.enum';
import { hideModal } from 'shared/components/modal/modal.actions';
import { PaymentOptionType } from 'shared/enums/settings/finance.enum';

import '../styles/quickpay-user-agreement-modal.component.scss';

export const QUICKPAY_USER_AGREEMENT_MODAL = 'QUICKPAY_USER_AGREEMENT_MODAL';

interface Props {
  isProcessingRequest: boolean;
  onLegalAgreementSubmit: (values: PaymentOptionOptInValues) => any;
}

export const QuickpayUserAgreementModal = ({ onLegalAgreementSubmit, isProcessingRequest }: Props) => {
  const { visible } = useModalState(QUICKPAY_USER_AGREEMENT_MODAL);
  const [form, setForm] = useState<AgreementForm>(null);
  const [isHiden, setHide] = useState(false);
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();

  const quickPaylegalAgreement = quickPayLegalAgreements[LegalAgreementType.QUICK_PAY_CONSENT]['1.0.0'];

  const hideModalAction = () => dispatch(hideModal(QUICKPAY_USER_AGREEMENT_MODAL));

  const onSubmit = useCallback(() => {
    onLegalAgreementSubmit({
      acceptedLegalAgreement: quickPaylegalAgreement,
      paymentOptionOptInType: PaymentOptionType.QuickPay,
      event: 'quickPaySubmitted',
      hideModalAction
    });
  }, [quickPaylegalAgreement]);

  useEffect(() => {
    if (visible) {
      const agreementForm = new AgreementForm();
      agreementForm.reset();
      agreementForm.changes.subscribe(() => forceUpdate());
      agreementForm.touch();
      setForm(agreementForm);
      setHide(value => !value);
    }
  }, [visible]);

  return (
    <Modal
      name={QUICKPAY_USER_AGREEMENT_MODAL}
      header="QUICK_PAY_AGREEMENT"
      disableClickingOffModalClose
      footer={<PaymentOptionButtonGroup hide={hideModalAction} disabled={(!!form && !!form.hasErrors())} isProcessing={isProcessingRequest} onSubmit={onSubmit}/>}
    >
      {form && (
        <AgreementOptionContext.Provider value={isHiden}>
          <span className="modal-description">{quickPaylegalAgreement.agreementDescription}</span>
          <AgreementOption agreementName="USER_AGREEMENT" agreementField={form.get('userAgreement')} agreementContent={quickPaylegalAgreement.userAgreement} />
          <AgreementOption
            agreementName="TERMS_AND_CONDITIONS_AGREEMENT"
            agreementField={form.get('termsAndConditionsAgreement')}
            agreementContent={quickPaylegalAgreement.termsAndConditions}
          />
        </AgreementOptionContext.Provider>
      )}
    </Modal>
  );
};
