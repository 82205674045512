import { Box } from '@mui/material';
import { Button } from 'shared/components/elements/elements.components';
import { useTranslation } from 'react-i18next';

import '../styles/preferences-feedback.component.scss';

export const PreferencesFeedback = () => {
  const { t } = useTranslation();
  return (
    <Box className="preferences-feedback">
      <div className="feedback-container">
        <Box sx={{ marginBottom: 3 }} className="font-bold font-size-md">
          {t("NOTIFICAITON_FEEDBACK_QUESTION")}
        </Box>
        <Button
          id="feedback-preferences-notification-button"
          btnDefault
          resource="FEEDBACK"
          mobileResponsive
        />
      </div>
    </Box>
  );
};
