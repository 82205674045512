export const EmailEntryContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: '#FFFFFF',
  borderRadius: '8px',
  padding: '24px',
  justifyContent: 'center',
}

export const EmailInput = {
  padding: '24px 0px',
  width: {
	xs: '328px',
	sm: '400px'
  }
}

export const EmailEntryButtonsContainer = {
  padding: '24px 0px 24px 24px',
  display: 'flex',
  justifyContent: 'flex-end'
}

export const EmailEntryButton = {
  width: '150px',
  minHeight: '50px',
  margin: '0px'
}

export const CancelButton = {
  ...EmailEntryButton,
  marginRight: '8px',
}