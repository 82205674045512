import * as v from 'app/forms/control/form-validators';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { groupStructure } from 'app/forms/structure/form-group.structure';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { ValidationFailureReason } from '@shared/enums/validation-failure-reason.enum';

interface ChangeEmailFormValues {
  email: string;
  confirmEmail: string;
}

export class ChangeEmailForm extends NavCarrierFormGroup<ChangeEmailFormValues> {
  constructor() {
    super(groupStructure({
      email: fieldStructure('', {validators: [v.required, v.email, v.maxLength(50)]}),
      confirmEmail: fieldStructure('', {validators: [v.required]})
    }));
    this.get('confirmEmail').addValidator(v.matchField(this.get('email')));
    this.get('email').addValidator(v.createValidator('apiError', () => !this.hasServerError(null, 4 as ValidationFailureReason)));
  }
}
