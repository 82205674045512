export const BreadCrumbButton = {
  textDecoration: 'none',
  color: 'primary.main', // Use the primary color for the link
  backgroundColor: 'transparent',
  border: 'none',
  padding: 0,
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'transparent',
  }
}

export const AddUserHeader = {
  padding: '16px 0px'
}