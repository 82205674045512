import { useMemo } from 'react';

import { useForceRefreshToken } from 'app/hooks/use-force-refresh-token.hook';
import { useUpdateOnFormChanges } from 'app/hooks/forms/use-update-on-form-changes.hooks';
import { InvoiceUploadForm } from 'pages/manage-invoices/upload/upload-invoice.form';

export const useInvoiceUploadForm = (): [InvoiceUploadForm, () => any] => {
  const [refreshToken, forceRefresh] = useForceRefreshToken();
  const form = useMemo(() => new InvoiceUploadForm(), [refreshToken]);

  useUpdateOnFormChanges(form);

  return [form, forceRefresh];
};
