import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { FormGroupStructure, groupStructure } from 'app/forms/structure/form-group.structure';

import * as v from 'app/forms/control/form-validators';

interface AgreementFormValues {
  userAgreement: boolean;
  termsAndConditionsAgreement: boolean;
}

export const createContainerFormStructure = (details?: Agreement): FormGroupStructure =>
  groupStructure({
    userAgreement: fieldStructure(details?.userAgreement ?? false, {
      validators: [v.requiredTrue],
    }),
    termsAndConditionsAgreement: fieldStructure(details?.termsAndConditionsAgreement ?? false, {
      validators: [v.requiredTrue],
    }),
  });

export class AgreementForm extends NavCarrierFormGroup<AgreementFormValues> {
  constructor(public details?: Agreement) {
    super(createContainerFormStructure(details));
  }
}
