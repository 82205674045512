import { useAjaxRequest } from '@app/hooks/ajax/use-ajax-request.hook';
import { APIErrorResponse } from '@app/repositories/errors/api-error-response';
import { PreferencesRepository } from '@app/repositories/preferences.repository';
import { useAjaxResponse } from 'app/hooks/ajax/use-ajax-response.hook';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { useState } from 'react';
import { Observable } from 'rxjs';
import { AjaxError } from 'rxjs/internal-compatibility';

export const useGetCommunicationPreferences = (
  contactId: string
): [PreferenceNotificationsResponse, AjaxError, () => void] => {
  const repo = useRepository(PreferencesRepository);
  const [error, setError] = useState<AjaxError>(null);

  const [preferences, refresh] = useAjaxResponse(() => {
    if (!contactId) {
      return Observable.empty();
    }

    return repo.getCommunicationPreferences(contactId).catch(e => {
      setError(e);
      return Observable.empty();
    });
  }, [contactId]);

  return [preferences, error, refresh];
};

export const useUpdateCommunicationPreferences = (
  onSuccess: () => any,
  onError: (error: Error | APIErrorResponse | AjaxError) => any
) => {
  const repo = useRepository(PreferencesRepository);

  const updateCommunicationPreferences = useAjaxRequest(
    (request: PreferenceNotificationsRequest) => {
      return repo.updateCommunicationPreferences(request);
    },
    onSuccess,
    [],
    onError
  );

  return updateCommunicationPreferences;
};
