export const ProfileSettingsContainer = {
  maxWidth: '820px'
}

export const SectionContainer = {
  backgroundColor: 'white',
  marginTop: '20px',
  padding: '24px 40px',
  borderRadius: '8px'
}

export const Grid = {
  paddingTop: '16px'
}

export const HiddenGridItem = {
  visibility: { sm: 'hidden' },
  display: {
	xs: 'none',
	sm: 'block',
  },
};

export const FormButton = {
  height: '53px',
  width: '50%',
  border: '1px solid #AAAEB0',
};

export const SelectedButton = {
  ...FormButton,
  background: 'rgba(0, 113, 199, 0.08)',
  border: '2px solid #0071C7 !important',
};

export const RadioButton = {
  border: '1px solid #AAAEB0',
  borderRadius: '8px',
  padding: '16px 16px 16px 0px',
  marginTop: '12px',
  width: '100%',
  '&:hover': {
	backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  height: '56px'
};

export const SelectedRadioButton = {
  ...RadioButton,
  background: 'rgba(0, 113, 199, 0.08)',
  border: '2px solid #0071C7',
  color: '#0071C7 !important',
};

export const RadioInput = {
  '&:hover': {
	backgroundColor: 'transparent',
  },
};

export const SelectedRadioHeader = {
  color: '#0071C7',
};

export const SubmitButtonContainer = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '16px'
}

export const CircularProgressIcon = {
  color: 'rgba(0, 0, 0, 0.28)',
};