export const HeaderContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '16px'
}

export const ContentContainer = {
  padding: '24px 32px',
  display: 'flex',
  flexDirection: 'column',
}

export const Dialog = {
  width: '718px'
}

export const ButtonBay = {
  paddingTop: '16px',
  justifyContent: 'flex-end'
}