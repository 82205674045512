import Checkbox from "@mui/material/Checkbox";
import { useCallback, useState } from "react";

import { useModal } from "app/hooks/use-modal.hook";
import { Button } from "shared/components/elements/button.component";
import { useModalState } from "shared/components/modal/hooks/use-modal-state.hook";
import { Modal } from "shared/components/modal/modal.component";
import { useTranslation } from 'react-i18next';
import { LegalAgreementType } from "shared/enums/legal-agreement/legal-agreement.enum";
import { CircularProgress, FormControlLabel, FormGroup } from "@mui/material";
import { PaymentOptionType } from "shared/enums/settings/finance.enum";
import { PaymentOptionButtonGroup } from "features/account-settings";

import "../styles/standardpay-user-agreement-modal.component.scss";

export const STANDARDPAY_USER_AGREEMENT_MODAL = "standard-pay-user-agreement-modal";

interface Props {
    isProcessingRequest: boolean;
    onLegalAgreementSubmit: (values: PaymentOptionOptInValues) => any;
}

export const StandardPayUserAgreementModal = ({ onLegalAgreementSubmit, isProcessingRequest }: Props) => {
    const [, hide] = useModal(STANDARDPAY_USER_AGREEMENT_MODAL);
    const modalState = useModalState(STANDARDPAY_USER_AGREEMENT_MODAL);
    const [checked, setCheck] = useState(false);
    const { t } = useTranslation();

    const onSubmit = useCallback(() => {
        onLegalAgreementSubmit({
            paymentOptionOptInType: PaymentOptionType.StandardPay,
            event: 'standardPaySubmitted',
            hideModalAction: hide,
            acceptedLegalAgreement: {
                legalAgreementType: LegalAgreementType.STANDARD_PAY_CONSENT,
                legalAgreementVersion: '1.0.0',
            },
        });
    }, []);

    const renderHeader = () => (
        <p className="standardpay-user-agreement-heading-text">
            {t("STANDARDPAY_ENROLLMENT")}
        </p>
    );

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => setCheck(e.target.checked);

    const renderBody = () => (
        <div>
            <p className="standardpay-user-agreement-body-text">
                {t("STANDARDPAY_USER_AGREEMENT")}
            </p>
            <FormGroup>
                <FormControlLabel
                    className="standardpay-user-agreement-body-text standardpay-user-agreement-label"
                    control={<Checkbox onChange={handleOnChange} checked={checked} />}
                    label={t("I_AGREE")}
                />
            </FormGroup>
        </div>
    );

    const Footer = (
        <div>
            <Button btnDefault resource="CANCEL" onClick={hide} />
            <Button btnPrimary disabled={!checked || isProcessingRequest} onClick={onSubmit} >
                {isProcessingRequest && <span><CircularProgress thickness={5} color="primary" size={20} /></span>} <span>{t("SUBMIT")}</span>
            </Button>
        </div>
    );

    return (
        <Modal
            name={STANDARDPAY_USER_AGREEMENT_MODAL}
            header={renderHeader}
            footer={<PaymentOptionButtonGroup hide={hide} disabled={!checked} isProcessing={isProcessingRequest} onSubmit={onSubmit}/>}
            disableClickingOffModalClose
        >
            {modalState?.visible && renderBody()}
        </Modal>
    );
};

