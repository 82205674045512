export const SidebarContainer = {
	minWidth: '256px',
	padding: '24px',
	borderRight: '1px solid #E5E6E7'
}

export const SidebarButton = {
	width: 'fit-content'
}

export const SelectedSidebarButton = {
	...SidebarButton,
	backgroundColor: 'rgba(0, 113, 199, 0.08)',
}

export const SelectedSidebarText = {
	color: 'rgba(0, 73, 134, 1)'
}

export const ActiveTab = {
	color: '#0071C7'
}

export const InactiveTab = {
	color: '#111112'
}

export const Tabs = {
	'& .MuiTabs-scroller': {
		display: 'flex',
		justifyContent: 'center'
	},
}