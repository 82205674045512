import { LegalAgreementType } from 'shared/enums/legal-agreement/legal-agreement.enum';

export interface QuickPayLegalAgreement {
  legalAgreementType: number;
  legalAgreementVersion: string;
  agreementDescription: JSX.Element;
  userAgreement: JSX.Element;
  termsAndConditions: JSX.Element;
}

export const quickPayLegalAgreements = {
  [LegalAgreementType.QUICK_PAY_CONSENT]: {
    '1.0.0': {
      legalAgreementType: LegalAgreementType.QUICK_PAY_CONSENT,
      legalAgreementVersion: '1.0.0',
      agreementDescription: (
        <>
          Quick Pay Discount Program: C.H. Robinson’s Quick Pay Program provides contracted motor carriers with the ability to choose to receive completed shipment compensation
          faster than the standard contracted payment period in exchange for agreeing to a discount on the contractually agreed shipment compensation amount (“Quick Pay Program”).
        </>
      ),
      userAgreement: (
        <>
          The individual signing this Agreement on behalf of Carrier expressly acknowledges and agrees that they possess the actual authority to bind Carrier to the terms and
          conditions set forth in this Agreement.
        </>
      ),
      termsAndConditions: (
        <>
          <p>
            <strong>Terms and Conditions:</strong> If you choose to participate in the Quick Pay Program, you (Carrier) and party to a Contract for Motor Contract Carrier Services
            (Contract) with C.H. Robinson, expressly acknowledge and agree to the following Quick Pay Program terms and conditions (Terms and Conditions) that relate to and govern
            Carrier's participation and/or continued participation in the C.H. Robinson Quick Pay Program:
          </p>
          <p>
            1. All Carriers who entered into the Contract with C.H. Robinson on or after March 20, 2016, made their request to participate in and registered for the Quick Pay
            Program as part of the RMIS Carrier Registration processes.
          </p>
          <p>
            2. All Carriers who entered into the Contract with C.H. Robinson before March 20, 2016, made their request to participate in and register for the Quick Pay Program by
            filling out and providing to C.H. Robinson a completed Quick Pay Discount Agreement.
          </p>
          <p>3. As of March 20, 2016, the Quick Pay Program and all Carriers' participation in the Quick Pay Program are governed by these Terms and Conditions.</p>
          <p>
            4. By participating in the Quick Pay Program, Carrier is expressly requesting that C.H. Robinson make early payment to Carrier of the freight charges earned by Carrier
            pursuant to the terms of the Contract, such early payment by C.H. Robinson in exchange for a discount of the agreed compensation earned by Carrier as provided for in
            Article 3 of the Contract.
          </p>
          <p>
            5. Upon final delivery of a shipment by Carrier and after providing the necessary documents to confirm the satisfactory completion of Carrier's responsibilities
            regarding the shipment (i.e. the shipment was delivered on-time and/or without suffering loss or damage to the freight being transported) pursuant to the Contract, C.H.
            Robinson agrees to pay to Carrier the amount of the freight bill as confirmed by Robinson, less a discount of two point zero percent (2.0%) of the gross freight bill
            amount (Discounted Amount). C.H. Robinson may determine to revise this discount rate from time to time, and will notify Carrier of any increase to the discount rate in
            accordance with the notice requirements set forth in these Terms and Conditions.
          </p>
          <p>
            6. Upon receiving the necessary documents (and after allowing for a reasonable processing time), C.H. Robinson will release payment of the Discounted Amount to Carrier
            within two (2) business days. The Discounted Amount will be paid to Carrier in the manner designated by Carrier in the Contract, which may consist of (a) depositing via
            electronic funds transfer (EFT) into Carrier's identified bank account, or (b) depositing into Carrier's T-Chek Account, or (c) issuing a T-Chek Authorization number to
            Carrier, or (d) delivery of check. 14800 Charlson Rd Suite 1000 | Eden Prairie, MN 55347 | P: 800 326 9977 | www.chrobinson.com © 2021 C.H. Robinson Worldwide Inc. All
            Rights Reserved.
          </p>
          <p>
            7. Instituting payment to Carrier within the Quick Pay Program and the first issuance of processing of the initial Quick Pay Program payment will take up to ten (10)
            business days from the receipt by C.H. Robinson of Carrier's request to participate in the Quick Pay Program.
          </p>
          <p>
            8. C.H. Robinson may determine, in its sole discretion, to provide alternative, lower discounts rates to Carriers as part of various motor carrier incentive and/or
            reward programs sponsored by C.H. Robinson (i.e. including but not limited to C.H. Robinson's Carrier Advantage™ Program). If a Carrier qualifies for a lower discount
            rate because of its participation in a C.H. Robinson sponsored incentive and/or reward program, C.H. Robinson may provide such lower discount rate to Carrier without
            providing notice to Carrier as set forth in these Terms and Conditions. C.H. Robinson will notify Carrier of any increase to the discount rate in accordance with the
            notice requirements set forth in these Terms and Conditions.
          </p>
          <p>
            9. C.H. Robinson may change, modify, add to and/or remove from these Terms and Conditions, expressly including increasing the discount rate (Changes) by providing
            Carrier with fifteen (15) days advance written notice of such Changes. Written notice may be issued to Carrier via a Load Confirmation, as defined in the Contract, or
            via email to Carrier's last known email address on file with C.H. Robinson, or via facsimile to Carrier's last known facsimile number on file with C.H. Robinson, or via
            registered mail to Carrier's last known mailing address on file with C.H. Robinson. Upon Carrier's receipt of a written notice identifying a Change, or at any time and
            for any reason or no reason, Carrier may contact C.H. Robinson at <u>CAFinancial@chrobinson.com</u> or 800-326-9977 and notify C.H. Robinson of Carrier's determination
            to stop participation in the Quick Pay Program. For the avoidance of doubt, if Carrier disagrees with any Changes, Carrier may stop its participation in the Quick Pay
            Program and its receipt of the Quick Pay service. Any and all Changes to the Terms and Conditions made by Robinson shall only apply prospectively from the expiration of
            the notice period notifying Carrier of the Changes.
          </p>
        </>
      ),
    } as QuickPayLegalAgreement,
  },
};
