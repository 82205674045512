import { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { Select, Typography } from '@chr/eds-react';
import { Translation } from '@shared/components/translation/translation.component';
import { MenuItem } from '@features/my-loads-v2/types';
import { CurrentItemSetDisplay } from 'shared/components/pagination-v2/current-item-set-display';
import './pagination-v2.scss';

export interface PaginationV2Props {
  totalItems: number;
  pageNumber: number;
  itemsPerPage: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  isBanner: boolean;
  closeDetails: () => void;
  tab?: string;
}

export const pageSizeMenuItems: Array<MenuItem> = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const PaginationV2 = ({
  totalItems,
  pageNumber,
  itemsPerPage,
  setPageNumber,
  setItemsPerPage,
  isBanner,
  closeDetails,
  tab,
}: PaginationV2Props) => {
  // reset the page number when itemsPerPage is changed
  useEffect(() => {
    setPageNumber(1);
  }, [itemsPerPage]);

  return (
    <div
      id={`${tab}-pagination-component`}
      className={`pagination-container ${isBanner ? 'pagination-with-banner' : ''}`}
      data-testid="pagination-container"
    >
      <div className="page-size-select">
        <div className="select-label" data-testid="select-label">
          <Typography variant="subtitle2">
            <Translation resource="SHOW_COLON" />
          </Typography>
        </div>
        <Select
          id="page-size-select"
          label=""
          menuItems={pageSizeMenuItems}
          value={itemsPerPage}
          onChange={e => {
            e.target.value && setItemsPerPage(e.target.value);
          }}
        />
        <CurrentItemSetDisplay totalItems={totalItems} pageNumber={pageNumber} itemsPerPage={itemsPerPage} />
      </div>
      <Pagination
        count={Math.ceil(totalItems / itemsPerPage)}
        page={pageNumber}
        onChange={(event, value) => {
          closeDetails();
          setPageNumber(value);
        }}
        shape="rounded"
        color="primary"
      />
    </div>
  );
};
