export const Form = {
 display: 'flex',
 justifyContent: 'space-between',
 flexDirection: 'column',
 alignItems: 'flex-start',
 padding: '16px',
 borderRadius: '4px',
 boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
 marginBottom: '32px',
 marginTop: '16px',
 backgroundColor: '#F6F7F8',
};

export const SearchBy = {
 marginBottom: '8px',
};

export const FiltersWrapper = {
 display: 'flex',
 flexDirection: {
  xs: 'column',
  sm: 'row',
 },
 gap: {
  xs: '8px',
  sm: '0px',
 },
 width: '100%',
};

export const FilterSelect = {
 marginRight: '4px',
 maxHeight: '56px',
 backgroundColor: '#FFFFFF',
};

export const SearchButton = {
 minWidth: '125px',
 height: '56px',
 textTransform: 'none',
 marginLeft: {
  xs: '0px',
  sm: '24px',
 },
};

export const ClearButton = {
 ...SearchButton,
 backgroundColor: '#FFFFFF',
};
