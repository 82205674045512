import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { TimeFormat } from 'shared/enums/time-format.enum';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { Culture } from 'app/i18n/culture.actions';
import { User } from 'shared/models/user.model';
import { groupStructure } from 'app/forms/structure/form-group.structure';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { CultureSelectForm } from 'shared/components/culture-select/culture-select.form';

interface AccountFormValues {
  startPage: string;
  unitOfMeasure: UnitOfMeasure;
  timeFormat: TimeFormat;
  timeZone: string;
  culture: {
    country: GlobalizationCountry;
    locale: GlobalizationCultureLanguage;
  };
  preferredCurrencyCode: string;
}

export class AccountForm extends NavCarrierFormGroup<AccountFormValues> {

  constructor(private user: User, startPages: string[], culture: Culture) {
    super(groupStructure({
      startPage: fieldStructure(startPages.includes(user.getStartPageKey()) ? user.getStartPageKey() : startPages[0]),
      timeFormat: fieldStructure(user.is12Hour() ? TimeFormat.TwelveHour : TimeFormat.TwentyFourHour),
      unitOfMeasure: fieldStructure(user.getUnitOfMeasure()),
      timeZone: fieldStructure(user.getTimeZone()),
      preferredCurrencyCode: fieldStructure(user.getPreferredCurrencyCode())
    }));

    this.addChild('culture', new CultureSelectForm());

    this.getCultureForm().setCulture(culture);
  }

  getCultureForm(): CultureSelectForm {
    return this.get('culture');
  }

  setCulture(culture: Culture) {
    const locale = (culture?.locale) || {
        countryCode: 'US',
        displayName: 'English',
        locale: 'en-US',
      };
    this.get('country').setValue(culture?.country);
    this.get('locale').setValue(locale);

    if (culture && (culture.country.locales.length === 1)) {
      this.get('locale').disable();
    }
  }

  toUserPatchRequest(): UserPatchRequest {
    const values = this.value;
    const country = values.culture.country;
    const culture = values.culture.locale;
    const defaultProperties = {
      country: 'US',
      culture: 'en-US'
    };

    return {
      userId: this.user.userId,
      properties: {
        ...defaultProperties,
        country: country?.code,
        culture: culture?.locale,
        startPage: values.startPage,
        timeFormat: values.timeFormat.toString(),
        timeZone: values.timeZone.toString(),
        unitOfMeasure: values.unitOfMeasure.toString(),
        preferredCurrencyCode: values.preferredCurrencyCode
      }
    };
  }
}
