import { useState, useEffect } from 'react';
import { useBodyClass } from 'features/registration/hooks/useBodyClass';
import { useAddClassName } from 'hooks/useAddClassName';
import {
  NavigationTabs,
  AccountSettingsTabs,
  AccountSettingsTabViews,
  ManageUsers,
  FinancialSettingComponent,
  NotificationsPreferencesComponent,
  AccountFormComponent,
  ManageDrivers,
  FINANCE_SETTING_SECTION,
  QUICK_PAY_OPT_IN,
  STANDARD_PAY_OPT_IN,
  PREFERENCES_SETTING_SECTION,
  MANAGE_DRIVERS_SECTION,
  ProfileSettings,
  usePaymentOption
} from 'features/account-settings';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import * as Styles from 'features/account-settings/styles';

export const AccountSettings = () => {
  useBodyClass('let-body-fill-page');
  useAddClassName('.account-settings-section', 'fill-page');

  const isSmallScreen = useMediaQuery('(max-width: 959px)')
  const [settingsTab, setSettingsTab] = useState(AccountSettingsTabs.PROFILE_SETTINGS);

  const hash = window?.location?.hash;
  const financeSettingsHashValues = [FINANCE_SETTING_SECTION, QUICK_PAY_OPT_IN, STANDARD_PAY_OPT_IN];
  const { webPreferencesNotificationsBraze, enableUserManagement } = useFlags();
  const activeMembership = useSelector(state => state.userManagement?.activeMembership);
  const { carrierCode } = useSelector(s => s.auth.user);
  const { paymentOption } = usePaymentOption(carrierCode);
  const isAdmin = activeMembership?.isAdmin;
  // If API don't send back valid payment option, do not show the finance setting section.
  const canViewFinancialSettings = !!paymentOption?.type;

  const navBarView = isSmallScreen ? AccountSettingsTabViews.TABS : AccountSettingsTabViews.SIDEBAR;

  const isManageUsers = settingsTab === AccountSettingsTabs.MANAGE_USERS;
  const isProfileSettings = settingsTab === AccountSettingsTabs.PROFILE_SETTINGS;
  const isFinancialSettings = settingsTab === AccountSettingsTabs.FINANCIAL_SETTINGS;
  const isNotificationSettings = settingsTab === AccountSettingsTabs.NOTIFICATION_SETTINGS;
  const isManageDrivers = settingsTab === AccountSettingsTabs.MANAGE_DRIVERS;

  // select tabs for corresponding hash values passed in url
  useEffect(() => {
	if (financeSettingsHashValues.some(x => hash.includes(x)) && canViewFinancialSettings) {
	  setSettingsTab(AccountSettingsTabs.FINANCIAL_SETTINGS);
	} else if (webPreferencesNotificationsBraze && hash.includes(PREFERENCES_SETTING_SECTION)) {
	  setSettingsTab(AccountSettingsTabs.NOTIFICATION_SETTINGS);
	} else if (!enableUserManagement && hash.includes(MANAGE_DRIVERS_SECTION)) {
	  setSettingsTab(AccountSettingsTabs.MANAGE_DRIVERS);
	}
  }, [hash, canViewFinancialSettings]);

  // if user is currently accessing manage users and their active membership changes to one they are not an admin of change to profile settings
  if (!isAdmin && isManageUsers) {
	setSettingsTab(AccountSettingsTabs.PROFILE_SETTINGS);
  }

  return (
	<Stack direction={isSmallScreen ? "column" : "row"} sx={Styles.AccountSettingsContainer}>
	  <NavigationTabs
		settingsTab={settingsTab}
		setSettingsTab={setSettingsTab}
		view={navBarView}
		isAdmin={isAdmin}
		canViewFinancialSettings={canViewFinancialSettings}
	  />
	  <Box sx={Styles.SettingsComponentContainer}>
		{isManageUsers && <ManageUsers />}
		{isProfileSettings && (enableUserManagement ? <ProfileSettings/> : <AccountFormComponent />)}
		{isFinancialSettings && <FinancialSettingComponent />}
		{isNotificationSettings && <NotificationsPreferencesComponent />}
		{isManageDrivers && <ManageDrivers />}
	  </Box>
	</Stack>    
  )
}