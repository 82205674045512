import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TextInput, Select, useSnackbar, SeverityType } from '@chr/eds-react';
import { putUserProfile } from 'api/userManagement';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';
import { getStartPagesAvailableToUser, setUserProfile, updateDefaultMembership } from 'features/permissions';
import { RegistrationPhoneNumberInput, PhoneNumberType } from 'features/registration';
import { parsePhoneNumber } from 'libphonenumber-js';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { TimeFormat } from 'shared/enums/time-format.enum';
import * as Styles from 'features/account-settings/profile-settings/styles/ProfileSettings.styles';

export const ProfileSettings = () => {
  const refDataRepo = new ReferenceDataRepository();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const { userProfile, memberships, activeMembership, email } = useSelector((s) => ({
	userProfile: s.userManagement.userProfile,
	memberships: s.userManagement.memberships,
	activeMembership: s.userManagement.activeMembership,
	email: s.okta.preferredEmailAddress
  })) || {};

  const availableStartPages = getStartPagesAvailableToUser(activeMembership?.permissions);
  const userId = activeMembership?.userId;
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [timeZones, setTimeZones] = useState([]);

  const {
	firstName,
	lastName,
	country,
	culture,
	preferredCurrencyCode,
	startPage,
	phone,
	phoneType,
	timeFormat,
	unitOfMeasure,
	timeZone
  } = userProfile || {};
  const defaultMembershipIdInitialValue = memberships?.find(s => s.isDefault)?.id || null;

  // fetch reference data
  useEffect(() => {
	const fetchReferenceData = async () => {
	  const countriesData = await refDataRepo.getGlobalizationLocales().toPromise();
	  const timeZonesData = await refDataRepo.getTimeZones().toPromise();

	  setCountries(countriesData);
	  setTimeZones(timeZonesData);
	};

	fetchReferenceData();
  }, []);

  // don't render component until redux has the user data
  if (!userProfile || !memberships || !activeMembership) return;

  // Validation schema with Yup
  const validationSchema = Yup.object().shape({
	firstName: Yup.string().required(t('FIRST_NAME_REQUIRED')),
	lastName: Yup.string().required(t('LAST_NAME_REQUIRED')),
	phone: Yup.string().nullable().test('is-valid-phone', t('PHONE_NUMBER_IS_INVALID'), value => {
	  if (!value) return false; // If value is empty, skip validation
	  try {
		const phoneNumber = parsePhoneNumber(value);
		return phoneNumber.isValid();
	  } catch (error) {
		return false;
	  }
	}),
	country: Yup.string().required(t('COUNTRY_IS_REQUIRED')),
	culture: Yup.string().required(t('CULTURE_IS_REQUIRED')),
	preferredCurrencyCode: Yup.string().required(t('PREFERRED_CURRENCY_IS_REQUIRED')),
	defaultMembershipId: Yup.string().required(t('DEFAULT_ACCOUNT_IS_REQUIRED')),
	startPage: Yup.string().required(t('DEFAULT_START_PAGE_IS_REQUIRED')),
	timeZone: Yup.string().required(t('TIMEZONE_IS_REQUIRED'))
  });

  const handleSubmission = async (values) => {
	setIsLoading(true);
	const { defaultMembershipId, ...requestBody } = values;
	try {
	  await putUserProfile(userId, requestBody);
	  if (defaultMembershipIdInitialValue != defaultMembershipId) {
		await dispatch(updateDefaultMembership({ userId: userId, membershipId: defaultMembershipId }));
	  }
	  dispatch(setUserProfile(values));
	  snackbar.enqueueSnackbar({
		id: 'profile-updated-success',
		message: t('PROFILE_SETTINGS_SAVED'),
		severity: 'success' as SeverityType,
	  });
	} catch {
	  snackbar.enqueueSnackbar({
		id: 'update-profile-failure',
		message: t('FALLBACK_SOMETHING_WENT_WRONG'),
		severity: 'error' as SeverityType,
	  });
	} finally {
	  setIsLoading(false);
	}
  };

  return (
	<Formik
	  initialValues={{
		firstName,
		lastName,
		email,
		phoneType,
		phone,
		startPage,
		country,
		culture,
		preferredCurrencyCode,
		unitOfMeasure,
		timeFormat,
		defaultMembershipId: defaultMembershipIdInitialValue,
		timeZone,
	  }}
	  validationSchema={validationSchema}
	  onSubmit={(values) => handleSubmission(values)}
	  validateOnMount
	>
	  {({ values, setValues, setFieldValue, handleSubmit, errors, isValid }) => {
		const availableLocales = countries.find(country => country.code === values.country)?.locales;
		const countryMenuItems = countries.map(country => ({ label: country.name, value: country.code }));
		const localeMenuItems = availableLocales ? availableLocales?.map(locale => ({ label: locale.displayName, value: locale.locale })) : [];
		const membershipMenuItems = memberships.map(
		  membership => ({ value: membership.id, label: `${membership.attributes?.companyName} - ${membership.companyCode}` })
		);
		const timeZoneMenuItems = timeZones.map((timeZone) => ({ value: timeZone.id, key: timeZone.id, label: timeZone.name }));

		return (
		  <Form onSubmit={handleSubmit}>
			<Stack direction="column" sx={Styles.ProfileSettingsContainer}>
			  <Typography variant="heading200">
				{t('PROFILE_SETTINGS')}
			  </Typography>
			  <Box sx={Styles.SectionContainer}>
				<Typography variant="heading300">
				  {t('USER_INFORMATION')}
				</Typography>
				<Grid container spacing={2} sx={Styles.Grid}>
				  <Grid item sm={6} xs={12}>
					<TextInput
					  label={t('FIRST_NAME')}
					  value={values.firstName}
					  onChange={(e) => setFieldValue('firstName', e.target.value)}
					  error={!!errors.firstName}
					  helperText={errors.firstName as string}
					  fullWidth
					/>
				  </Grid>
				  <Grid item sm={6} xs={12}>
					<TextInput
					  label={t('LAST_NAME')}
					  value={values.lastName}
					  onChange={(e) => setFieldValue('lastName', e.target.value)}
					  error={!!errors.lastName}
					  helperText={errors.lastName as string}
					  fullWidth
					/>
				  </Grid>
				  <Grid item sm={6} xs={12}>
					<TextInput
					  label={t('EMAIL_ADDRESS')}
					  value={values.email}
					  onChange={(e) => setFieldValue('email', e.target.value)}
					  helperText={t('EMAIL_ADDRESS_CANT_BE_EDITED')}
					  disabled
					  fullWidth
					/>
				  </Grid>
				  <Grid item sm={6} xs={12} sx={Styles.HiddenGridItem} />
				  <Grid item sm={6} xs={12}>
					<InputLabel>{t('PHONE_NUMBER')}</InputLabel>
					<ButtonGroup fullWidth>
					  <Button
						sx={values.phoneType === PhoneNumberType.MOBILE ? Styles.SelectedButton : Styles.FormButton}
						onClick={(e) => setFieldValue('phoneType', PhoneNumberType.MOBILE)}
						value={PhoneNumberType.MOBILE}
					  >
						{t('MOBILE')}
					  </Button>
					  <Button
						sx={values.phoneType === PhoneNumberType.BUSINESS ? Styles.SelectedButton : Styles.FormButton}
						onClick={(e) => setFieldValue('phoneType', PhoneNumberType.BUSINESS)}
						value={PhoneNumberType.BUSINESS}
					  >
						{t('BUSINESS')}
					  </Button>
					</ButtonGroup>
				  </Grid>
				  <Grid item sm={6} xs={12}>
					<RegistrationPhoneNumberInput
					  phoneNumber={values.phone}
					  handlePhoneNumber={e => setFieldValue('phone', e)}
					  error={errors.phone as string}
					/>
				  </Grid>
				</Grid>
			  </Box>
			  <Box sx={Styles.SectionContainer}>
				<Typography variant="heading300">
				  {t('PREFERENCES')}
				</Typography>
				<Grid container spacing={2} sx={Styles.Grid}>
				  <Grid item sm={6} xs={12}>
					<Select
					  label={t('COUNTRY')}
					  menuItems={countryMenuItems}
					  onChange={(e) => {
						setValues({
						  ...values,
						  country: e.target.value,
						  culture: countries.find(country => country.code === e.target.value)?.locales[0]?.locale || '',
						});
					  }}
					  value={values.country}
					  fullWidth
					  error={!!errors.country}
					  helperText={errors.country as string}
					/>
				  </Grid>
				  <Grid item sm={6} xs={12}>
					<Select
					  label={t('LANGUAGE')}
					  menuItems={localeMenuItems}
					  onChange={(e) => setFieldValue('culture', e.target.value)}
					  value={values.culture}
					  disabled={availableLocales?.length == 1}
					  fullWidth
					  error={!!errors.culture}
					  helperText={errors.culture as string}
					/>
				  </Grid>
				  <Grid item sm={6} xs={12}>
					<Select
					  label={t('CURRENCY')}
					  menuItems={[
						{ value: 'USD', label: 'USD' },
						{ value: 'CAD', label: 'CAD' }
					  ]}
					  onChange={(e) => setFieldValue('preferredCurrencyCode', e.target.value)}
					  value={values.preferredCurrencyCode}
					  error={!!errors.preferredCurrencyCode}
					  helperText={errors.preferredCurrencyCode as string}
					  fullWidth
					/>
				  </Grid>
				  <Grid item sm={6} xs={12} sx={Styles.HiddenGridItem} />
				  <Grid item sm={6} xs={12}>
					<InputLabel>{t('MEASUREMENTS')}</InputLabel>
					<Stack direction="row" spacing={2}>
					  <FormControlLabel
						value={UnitOfMeasure.US}
						onChange={() => setFieldValue('unitOfMeasure', UnitOfMeasure.US)}
						checked={values.unitOfMeasure === UnitOfMeasure.US}
						control={<Radio sx={Styles.RadioInput} />}
						sx={values.unitOfMeasure === UnitOfMeasure.US ? Styles.SelectedRadioButton : Styles.RadioButton}
						label={
						  <Typography variant="button" sx={values.unitOfMeasure === UnitOfMeasure.US ? Styles.SelectedRadioHeader : {}}>
							{t('STANDARD')}
						  </Typography>
						}
					  />
					  <FormControlLabel
						value={UnitOfMeasure.Metric}
						onChange={() => setFieldValue('unitOfMeasure', UnitOfMeasure.Metric)}
						checked={values.unitOfMeasure === UnitOfMeasure.Metric}
						control={<Radio sx={Styles.RadioInput} />}
						sx={values.unitOfMeasure === UnitOfMeasure.Metric ? Styles.SelectedRadioButton : Styles.RadioButton}
						label={
						  <Typography variant="button" sx={values.unitOfMeasure === unitOfMeasure.Metric ? Styles.SelectedRadioHeader : {}}>
							{t('METRIC')}
						  </Typography>
						}
					  />
					</Stack>
				  </Grid>
				  <Grid item sm={6} xs={12} sx={Styles.HiddenGridItem} />
				  <Grid item sm={6} xs={12}>
					<Select
					  label={t('DEFAULT_ACCOUNT_ON_LOGIN')}
					  menuItems={membershipMenuItems}
					  onChange={(e) => setFieldValue('defaultMembershipId', e.target.value)}
					  value={values.defaultMembershipId}
					  fullWidth
					  error={!!errors.defaultMembershipId}
					  helperText={errors.defaultMembershipId as string}
					/>
				  </Grid>
				  <Grid item sm={6} xs={12}>
					<Select
					  label={t('DEFAULT_START_PAGE')}
					  menuItems={availableStartPages}
					  onChange={(e) => setFieldValue('startPage', e.target.value)}
					  value={values.startPage}
					  fullWidth
					  error={!!errors.startPage}
					  helperText={errors.startPage as string}
					/>
				  </Grid>
				  <Grid item sm={6} xs={12}>
					<Select
					  label={t('TIMEZONE')}
					  menuItems={timeZoneMenuItems}
					  onChange={(e) => setFieldValue('timeZone', e.target.value)}
					  value={values.timeZone}
					  fullWidth
					  error={!!errors.timeZone}
					  helperText={errors.timeZone as string}
					/>
				  </Grid>
				  <Grid item sm={6} xs={12}>
					<InputLabel>{t('TIME_FORMAT')}</InputLabel>
					<Stack direction="row" spacing={2}>
					  <FormControlLabel
						value={TimeFormat.TwelveHour}
						onChange={() => setFieldValue('timeFormat', TimeFormat.TwelveHour)}
						checked={values.timeFormat === TimeFormat.TwelveHour}
						control={<Radio sx={Styles.RadioInput} />}
						sx={values.timeFormat === TimeFormat.TwelveHour ? Styles.SelectedRadioButton : Styles.RadioButton}
						label={
						  <Typography variant="button" sx={values.timeFormat === TimeFormat.TwelveHour ? Styles.SelectedRadioHeader : {}}>
							{t('12_HOUR')}
						  </Typography>
						}
					  />
					  <FormControlLabel
						value={TimeFormat.TwentyFourHour}
						onChange={() => setFieldValue('timeFormat', TimeFormat.TwentyFourHour)}
						checked={values.timeFormat === TimeFormat.TwentyFourHour}
						control={<Radio sx={Styles.RadioInput} />}
						sx={values.timeFormat === TimeFormat.TwentyFourHour ? Styles.SelectedRadioButton : Styles.RadioButton}
						label={
						  <Typography variant="button" sx={values.timeFormat === TimeFormat.TwentyFourHour ? Styles.SelectedRadioHeader : {}}>
							{t('24_HOUR')}
						  </Typography>
						}
					  />
					</Stack>
				  </Grid>
				</Grid>
			  </Box>
			  <Box sx={Styles.SubmitButtonContainer}>
				<Button
				  onClick={() => handleSubmit()}
				  variant="contained"
				  startIcon={isLoading && <CircularProgress sx={Styles.CircularProgressIcon} size={16} />}
				  disabled={!isValid || isLoading}
				  sx={{ width: '185px' }}
				  data-testid='save-btn'
				>
				  {t('SAVE')}
				</Button>
			  </Box>
			</Stack>
		  </Form>
		)
	  }}
	</Formik>
  );
}