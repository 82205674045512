import { useEffect, useState } from 'react';

export const useSubtitles = (key) => {
  const subtitles = [
    {
      key: 'carrier_advantage_program',
      value: 'STAY_INFORMED_ON_YOUR_TRENDING_SCORE'
    },
    {
      key: 'booked_cancelled_loads',
      value: 'GET_AN_UPDATE_WHEN_YOUR_CARRIER'
    },
    {
      key: 'load_detail_update',
      value: 'REAL_TIME_UPDATES'
    },
    {
      key: 'appointment_update',
      value: 'WHEN_APPOINTMENTS_ARE_SET'
    },
    {
      key: 'tracking_update',
      value: 'TRACKING_AND_ON_TIME_PERFORMANCE'
    },
    {
      key: 'suggested_loads',
      value: 'RECEIVE_NOTIFICATIONS_ON_SOME_LOADS'
    }
  ];

    const [value, setValue] = useState('');

    useEffect(() => {
        const subtitle = subtitles.find(subtitle => subtitle.key === key);
        if (subtitle) {
            setValue(subtitle.value);
        }
    }, [key]);

  return value;
};

export const useTitles = (key) => {
  const titles = [
    {
      key: 'carrier_advantage_program',
      value: 'CARRIER_ADVANTAGE_PROGRAM'
    },
    {
      key: 'booked_cancelled_loads',
      value: 'BOOKED_CANCELLED_LOADS'
    },
    {
      key: 'load_detail_update',
      value: 'LOAD_DETAIL_UPDATE'
    },
    {
      key: 'appointment_update',
      value: 'APPOINTMENT_UPDATE'
    },
    {
      key: 'tracking_update',
      value: 'TRACKING_UPDATE'
    }
  ];

    const [value, setValue] = useState('');

    useEffect(() => {
        const title = titles.find(title => title.key === key);
        if (title) {
            setValue(title.value);
        }
    }, [key]);

  return value;
}

export const useCommunications = (list) => {
  const communications = [
    {
      key: 'cap_15_days',
      value: 'TRENDING_15_DAYS'
    },
    {
      key: 'cap_7_days',
      value: 'TRENDING_7_DAYS'
    },
    {
      key: 'loads_booked',
      value: 'WHEN_A_LOAD_IS_BOOKED'
    },
    {
      key: 'loads_cancelled',
      value: 'WHEN_A_LOAD_IS_CANCELLED'
    },
    {
      key: 'stop_created',
      value: 'WHEN_A_STOP_IS_CREATED'
    },
    {
      key: 'stop_removed',
      value: 'WHEN_A_STOP_IS_REMOVED'
    },
    {
      key: 'expected_pallet_count_changed',
      value: 'WHEN_A_PALLET_COUNT_IS_CHANGED'
    },
    {
      key: 'expected_weight_changed',
      value: 'WHEN_THE_WEIGHT_HAS_CHANGED'
    },
    {
      key: 'appointment_set',
      value: 'WHEN_AN_APPOINTMENT_IS_SET'
    },
    {
      key: 'appointment_changed',
      value: 'WHEN_AN_APPOINTMENT_IS_CHANGED'
    },
    {
      key: 'tracking_load_not_tracking_message',
      value: 'WHEN_LOADS_ARE_MISSING'
    }
  ]

  const [value, setValue] = useState([]);

  useEffect(() => {
    const communicationList = list.map(communication => {
      const communicationValue = communications.find(communicationValue => communicationValue.key === communication.key);
      if (communicationValue) {
        return communicationValue.value;
      }
    });
    setValue(communicationList);
  }, [list]);

  return value;
}