import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/elements/elements.components';
import { Box } from '@mui/material';

import '../styles/preferences-call-to-actions.component.scss';

export const PreferencesCallToAction = ({
  unsavedChanges = 0,
  onCancel,
  onSave,
}: {
  unsavedChanges?: number;
  onCancel: () => any;
  onSave: () => any;
}) => {
  const { t } = useTranslation()

  if (unsavedChanges === 0) return null;

  return (
    <Box
      className="preferences-call-to-actions"
      sx={{ backgroundColor: '#F6F7F8', borderRadius: '4px', borderColor: '#E5E6E7' }}
    >
      <div className="cta-container">
        <span className="font-bold">
          {unsavedChanges > 0 && t("NOTIFICATIONS_CHANGED", { 0: unsavedChanges })}
        </span>
        <Box className="cta-buttons">
          <Button type="reset" btnDefault resource="CANCEL" onClick={onCancel} />
          <Button type="submit" btnPrimary resource="APPLY_CHANGES" onClick={onSave} />
        </Box>
      </div>
    </Box>
  );
};
