import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DesktopAccessDisabled from '@mui/icons-material/DesktopAccessDisabled';
import AccessTime from '@mui/icons-material/AccessTime';
import { useTranslation } from 'react-i18next';
import { MembershipStatus, useDisplayRolesForOrgType, ManageUsersViews, RemoveUserDialog } from 'features/account-settings';
import { UserManagerSelection } from './CompanyInformationUserManager';
import EditUserStatus from './CompanyInformationStatus';
import { Button } from '@mui/material';
import * as Styles from 'features/account-settings/manage-users/styles';
import { deleteMembership } from 'api/userManagement';
import { useSnackbar, SeverityType } from '@chr/eds-react';

export interface CompanyInformationProps {
  isSaving: boolean;
  membership: Membership;
  organization: Organization;
  isAdmin: Boolean;
  setIsAdmin: (value: Boolean) => void;
  membershipStatus: MembershipStatus;
  setMembershipStatus: (status: MembershipStatus) => void;
  role: string;
  setRole: (role: string) => void;
  userData?: MembershipsUser & {userId: string};
  setView: Dispatch<SetStateAction<symbol>>;
}

export const CompanyInformation = ({
    isSaving,
    membership,
    organization,
    membershipStatus,
    setMembershipStatus,
    isAdmin,
    setIsAdmin,
    role,
    setRole,
    userData,
    setView,
}: CompanyInformationProps) => {
    const [status, setStatus] = useState({
        displayText: '',
        color: '',
        icon: null,
    });
    const [selectRoleError, setSelectRoleError] = useState(false);
    const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] = useState(false);
    const [isLoadingRemoveUser, setIsLoadingRemoveUser] = useState(false);

    const { t } = useTranslation();
	const snackbar = useSnackbar();

    const statusMapper = {
        [MembershipStatus.ACTIVE]: {
            displayText: 'Active',
            color: '#005C05',
            icon: <CheckCircleOutlineOutlinedIcon fontSize='small'/>,
        },
        [MembershipStatus.DISABLED]: {
            displayText: 'Suspended',
            color: '#B54D08',
            icon: <DesktopAccessDisabled fontSize='small'/>,
        },
        [MembershipStatus.PENDING]: {
            displayText: 'Invitation Sent',
            color: 'rgba(0, 113, 199, 1)',
            icon: <AccessTime fontSize='small'/>,
        },
    };

    useEffect(() => {
        if (membership) {
            setRole(membership.roles[0]);
            const { displayText, color, icon } = statusMapper[membership.status];
            setStatus({ displayText, color, icon });
            setMembershipStatus(membership.status);
            setIsAdmin(membership.isAdmin);
        }
    }, [membership]);

    const handleRoleUpdate = role => {
        setRole(role);
        setSelectRoleError(false);
    };

    const handleDeleteMembership = (membershipId: string) => {
        setIsLoadingRemoveUser(true);
        deleteMembership(userData.userId, membershipId)
            .then(() => {
                setView(ManageUsersViews.MANAGE_USERS);
                snackbar.enqueueSnackbar({
                    id: 'remove-user-success',
                    message: t('REMOVE_USER_SUCCESS'),
                    severity: 'success' as SeverityType,
                });
            })
            .catch(() => {
                snackbar.enqueueSnackbar({
                    id: 'remove-user-failure',
                    message: t('FALLBACK_SOMETHING_WENT_WRONG'),
                    severity: 'error' as SeverityType,
                });
            })
            .finally(() => {
                setIsRemoveUserDialogOpen(false);
                setIsLoadingRemoveUser(false);
            });
      };

    return (
        <Box sx={Styles.ContainerStyle}>
            <Typography variant="h4">{t('COMPANY_INFORMATION')}</Typography>
            <Accordion sx={Styles.AccordionStyle} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ marginTop: '8px' }} />}>
                    <Box sx={Styles.AccordionSummaryBoxStyle}>
                        <Box sx={Styles.HeaderStyle}>
                            <Typography variant="h3">{`${membership?.attributes.companyName} - ${membership?.companyCode}`}</Typography>
                            {membership && (
                                <Box sx={Styles.StatusStyle(status.color)}>
                                    {status.icon} {status.displayText}
                                </Box>
                            )}
                        </Box>
                        <Box sx={Styles.RoleBoxStyle}>
                            <Typography variant="body2" sx={{ color: '#212121', marginRight: '2px' }}>
                                Role
                            </Typography>
                            {membership?.roles.map((role: string) => (
                                <Box sx={Styles.RoleChipStyle} key={role}>
                                    <Typography variant="body2" sx={{ color: '#212121', letterSpacing: '0.5px' }}>
                                        {role}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </AccordionSummary>
                <Box sx={Styles.AccordionPaddingStyle}>
                    <Typography variant="h4" sx={{ fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>
                        {t('ROLE_AND_PERMISSIONS')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mt: '20px' }}>
                        {useDisplayRolesForOrgType(organization, role, handleRoleUpdate)}
                    </Box>
                </Box>
                <Box sx={Styles.MarginTopStyle}>
                    <UserManagerSelection isAdmin={isAdmin} setIsAdmin={setIsAdmin} />
                </Box>
                <Box sx={Styles.UserManagerBoxStyle}>
                    <EditUserStatus status={membershipStatus} setStatus={setMembershipStatus} />
                </Box>
                <Box sx={Styles.DeleteContainerStyle}>
                    <Button
                        data-testid="remove-membership"
                        sx={Styles.RemoveButtonStyle}
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsRemoveUserDialogOpen(true) }
                        disabled={isSaving}
                    >
                        {t('REMOVE')}
                    </Button>
                    <RemoveUserDialog
                        name={`${userData?.firstName} ${userData?.lastName}`}
                        company={membership?.attributes?.companyName}
                        isOpen={isRemoveUserDialogOpen}
                        isLoading={isLoadingRemoveUser}
                        onClose={() => setIsRemoveUserDialogOpen(false)}
                        onConfirm={() => handleDeleteMembership(membership?.id)}
                    />
                </Box>
            </Accordion>
        </Box>
    );
};
