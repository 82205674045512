export const UserInformationContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: '#FFFFFF',
  borderRadius: '8px',
  padding: '24px',
  justifyContent: 'center',
}

export const AdminSwitch = {
  '& .MuiSwitch-input': {
	position: 'absolute'
  }
}

export const SwitchLabelContainer = {
  paddingTop: '6px'
}

export const SwitchContainer = {
  paddingTop: '16px'
}

export const RoleErrorContainer = {
  color: '#8E0000',
  alignItems: 'center',
  paddingTop: '16px'
}