import Typography from "@mui/material/Typography";
import { useTranslate } from "@shared/components/translation/translation.component";

export interface CurrentItemSetDisplayProps {
  totalItems: number;
  pageNumber: number;
  itemsPerPage: number;
}

export const CurrentItemSetDisplay = ({ totalItems, pageNumber, itemsPerPage }: CurrentItemSetDisplayProps) => {
  const translate = useTranslate();

  const isLastPage = Math.ceil(totalItems / itemsPerPage) === pageNumber;
  const firstItemNumberDisplayed = (pageNumber - 1) * itemsPerPage + 1;
  const lastItemNumberDisplayed = isLastPage ? totalItems : pageNumber * itemsPerPage;

  const displayString = `${translate("ROWS")} ${firstItemNumberDisplayed}-${lastItemNumberDisplayed} ${translate("OF")} ${totalItems}`;

  return (
    <div className="row-number-display" data-testid="row-number-display">
      <Typography variant="body1">
        {displayString}
      </Typography>
    </div>
  )
}
