import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import DesktopAccessDisabled from '@mui/icons-material/DesktopAccessDisabled';
import { CheckCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as Styles from 'features/account-settings/manage-users/styles';
import { MembershipStatus } from '../enums/CompanyInformationEnum';

interface EditUserStatusProps {
  status: MembershipStatus;
  setStatus: (status: MembershipStatus) => void;
}

export default function EditUserStatus({ status, setStatus }: EditUserStatusProps) {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.StatusContainer}>
      <Typography variant="subtitle1">{t('STATUS')}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', alignSelf: 'center', marginLeft: '2px' }}>
        <FormControl sx={{ width: '100%' }} component="fieldset">
          <RadioGroup sx={Styles.RadioGroupStyles} row aria-label="status" name="status" value={status}>
            <FormControlLabel
              sx={Styles.FormControlLabelActiveStyles(status === MembershipStatus.ACTIVE)}
              value={MembershipStatus.ACTIVE}
              control={<Radio sx={Styles.RadioBtn} />}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={Styles.ActiveIconWrapper}>
                    <CheckCircleOutline />
                    <Typography data-testid="status-active" sx={Styles.ActiveTextStyles} variant="h4">
                      {t('ACTIVE')}
                    </Typography>
                  </Box>
                  <Typography data-testid="status-active-text" sx={Styles.ActiveDescriptionStyles} variant="h3">
                    {t('USER_CAN_ACCESS_THEIR_ACCOUNT')}
                  </Typography>
                </Box>
              }
              onChange={(_, checked) => {
                if (checked && setStatus) {
                  setStatus(MembershipStatus.ACTIVE);
                }
              }}
            />
            <FormControlLabel
              sx={Styles.FormControlLabelSuspendedStyles(status === MembershipStatus.DISABLED)}
              value={MembershipStatus.DISABLED}
              control={<Radio sx={Styles.RadioBtn} />}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={Styles.SuspendedIconWrapper}>
                    <DesktopAccessDisabled />
                    <Typography data-testid="status-suspended" sx={Styles.SuspendedTextStyles} variant="h4">
                      {t('SUSPENDED')}
                    </Typography>
                  </Box>
                  <Typography data-testid="status-suspended-text" sx={Styles.SuspendedDescriptionStyles} variant="h3">
                    {t('TEMPORARILY_RESTRICT_THE_USER')}
                  </Typography>
                </Box>
              }
              onChange={(_, checked) => {
                if (checked && setStatus) {
                  setStatus(MembershipStatus.DISABLED);
                }
              }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
