import { useTranslation } from 'react-i18next';
import { Switch } from "@chr/eds-react";
import { useCommunications, useSubtitles, useTitles } from "features/account-settings";
import { useCallback } from "react";
import { useStoredUser } from "@app/hooks/store/use-stored-user.hook";

import '../styles/notification-row.component.scss';

export const NotificationRow = ({ title, subtitle, list, isEnabled, handleCheck }) => {

    const subtitleText = useSubtitles(subtitle);
    const titleText = useTitles(title);
    const communicationsListText = useCommunications(list);
    const user = useStoredUser();
    const enableCarrierAdvantage = user?.roles?.TMC && !user?.roles?.FindLoads && title === 'carrier_advantage_program';
    const handleChange = useCallback(()=>{
        handleCheck(title)
    },[handleCheck, title])
    const { t } = useTranslation();

    if(enableCarrierAdvantage) return null;

    return (
        <div className="notification-row">
            <div className="notification-row-container">
                <div className="notification-row-title">
                    <div>{t(titleText)}</div>
                </div>
                <div className="notification-row-subtitle">{t(subtitleText)}</div>
                {isEnabled && list?.length > 0 && <div className="notification-row-notify">
                    <div>We will notify you of the following:</div>
                    <ul className="notification-row-list">
                        {communicationsListText.map((item, index) => {
                            return (
                                <li key={index}>
                                    {t(item)}
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>}
            </div>
            <div>
                <span className="switch-inline checkbox-inline">
                    <Switch
                        id={title}
                        checked={isEnabled}
                        onChange={handleChange}
                        ariaLabel={title}
                    />
                </span>
            </div>
        </div>
    );
}