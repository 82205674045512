export const RadioButton = {
  border: '1px solid #AAAEB0',
  borderRadius: '8px',
  padding: '16px 16px 16px 0px',
  marginTop: '12px',
  width: '100%',
  alignItems: 'flex-start',
  '&:hover': {
	  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
};

export const SelectedRadioButton = {
  ...RadioButton,
  background: 'rgba(0, 113, 199, 0.08)',
  border: '2px solid #0071C7',
  color: '#0071C7 !important',
};

export const RadioHeader = {
  fontWeight: '700',
  fontSize: '14px'
}

export const SelectedRadioHeader = {
  ...RadioHeader,
  color: '#0071C7',
};

export const RadioInput = {
  padding: '0px 9px',
  '&:hover': {
	backgroundColor: 'transparent',
  },
  '& .PrivateSwitchBase-input': {
	position: 'absolute'
  }
};

export const Permission = {
  padding: '4px',
  borderRadius: '4px',
  backgroundColor: '#F6F7F8',
  margin: '4px !important'
}

export const RoleBody = {
  padding: '8px 0px'
}

export const AccessTo = {
  padding: '8px 0px'
}

export const PermissionsStack = {
  flexWrap: 'wrap'
}