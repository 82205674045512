export const HeaderContainer = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const TableContainer = {
  flexGrow: 1,
  overflow: 'auto',
  padding: '24px 40px',
  backgroundColor: 'white',
  marginTop: '20px',
  borderRadius: '8px',
};

export const MyUsersHeader = { mb: '32px' };
