import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAnalyticsTrack } from '@chr/react-analytics';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import {
  PaymentOptionDialogModal,
  PAYMENT_OPTION_DIALOG_MODAL,
  StandardPayUserAgreementModal,
  STANDARDPAY_USER_AGREEMENT_MODAL,
  QuickpayUserAgreementModal,
  QUICKPAY_USER_AGREEMENT_MODAL,
  usePaymentOption,
  TriumphSignupDialog
} from 'features/account-settings';
import { useTranslation } from 'react-i18next';
import { FinancialsRepository } from 'app/repositories/financials.repository';
import { showModal } from 'shared/components/modal/modal.actions';
import { CheckCircleOutline, ThumbUpAltOutlined } from '@mui/icons-material';
import { UserRepository } from 'app/repositories/user.repository';
import LegalAgreementStub from 'shared/models/settings/legal-agreement-stub.model';
import { PaymentMethodType, PaymentOptionType } from 'shared/enums/settings/finance.enum';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';

import * as Styles from 'features/account-settings/financial-settings/styles/FinancialSettingsStyles';

export const FINANCE_SETTING_SECTION = 'finance-setting-section';
export const QUICK_PAY_OPT_IN = 'quick-pay-opt-in';
export const STANDARD_PAY_OPT_IN = 'standard-pay-opt-in';
export const FAAS_V_CODE = 'V66544';

export const FinancialSettingComponent = () => {
    const { carrierCode, username, userId, contactId }  = useSelector(s => s.auth.user);
    const track = useAnalyticsTrack();
    const dispatch = useDispatch();
    const financialRepo = useRepository(FinancialsRepository);
    const userRepo = useRepository(UserRepository);
    const [isProcessing, setIsProcessing] = useState(false);
    const { paymentOption, isEligibleForQuickPay, forceRefreshCallback } = usePaymentOption(carrierCode);
    const { t } = useTranslation();
    const { enableFaas } = useFlags();
    const hash = window?.location?.hash;
    const [showTriumphDialog, setShowTriumphDialog] = useState(false);

    const learnMoreLink = `https://www.chrobinson.com/en-us/carriers/carrier-financial-services/factoring/?mdmcontactdetailid=${contactId}&navcarrierid=${userId}&utm_medium=product&utm_source=navcarrierweb&utm_campaign=car-faas-settings&utm_content=cta_learn_more`;

    const getNewLegalAgreementStub = (legalAgreements: LegalAgreementStub[], acceptedLegalAgreement: LegalAgreement) => {
        const latestReferenceNumber = legalAgreements
            ?.reduce((refNum, current) => (current?.referenceNumber > refNum) ? current.referenceNumber : refNum, 0) ?? 0;

        const { legalAgreementType, legalAgreementVersion } = acceptedLegalAgreement;

        const legalAgreementStub: LegalAgreementStub = {
            legalAgreementType,
            legalAgreementVersion,
            userId,
            referenceNumber: latestReferenceNumber + 1
        };

        return legalAgreementStub;
    }

    const updatePaymentOption = (acceptedLegalAgreement: LegalAgreement, paymentOptionOptInType: number, hideModalAction: Function) => {
        const { legalAgreementType, legalAgreementVersion } = acceptedLegalAgreement;

        const newPaymentOption: UpdatePaymentOption = {
            type: paymentOptionOptInType,
            legalAgreementType,
            legalAgreementVersion
        };

        financialRepo
            .putPaymentOption(carrierCode, newPaymentOption)
            .subscribe(() => {
                setIsProcessing(false);
                hideModalAction();
                forceRefreshCallback();
            });
    }

    const onLegalAgreementSubmit = useCallback((values: PaymentOptionOptInValues) => {
        setIsProcessing(true);
        const { acceptedLegalAgreement, event, hideModalAction, paymentOptionOptInType } = values;

        track({ event });

        userRepo
            .getLegalAgreements(username, acceptedLegalAgreement.legalAgreementType)
            .subscribe((legalAgreements: LegalAgreementStub[]) => userRepo
                .addLegalAgreement(username, getNewLegalAgreementStub(legalAgreements, acceptedLegalAgreement))
                .subscribe(() => updatePaymentOption(acceptedLegalAgreement, paymentOptionOptInType, hideModalAction)));
    }, [username]);

    // If API don't send back valid payment option, do not show the finance setting section.
    if (!paymentOption?.type) {
        return null;
    }

    const isQuickPay = paymentOption.type === PaymentOptionType.QuickPay;
    const isStandardPay = paymentOption.type === PaymentOptionType.StandardPay;
    const isExistingFactoring = paymentOption.type === PaymentOptionType.Factoring && paymentOption.factoringId != FAAS_V_CODE;
    const isFaasFactoring = paymentOption.type === PaymentOptionType.Factoring && paymentOption.factoringId === FAAS_V_CODE;
    const isEFT = paymentOption?.paymentMethod === PaymentMethodType.EFT;
    const isTPAY = paymentOption?.paymentMethod === PaymentMethodType.TPAY;

    const handleSelectButtonClick = () => {
        if (isQuickPay && !hash.includes(STANDARD_PAY_OPT_IN)) {
            return dispatch(showModal(PAYMENT_OPTION_DIALOG_MODAL));
        }

        if (isQuickPay && hash.includes(STANDARD_PAY_OPT_IN)) {
            return dispatch(showModal(STANDARDPAY_USER_AGREEMENT_MODAL));
        }

        dispatch(showModal(QUICKPAY_USER_AGREEMENT_MODAL));
        track({ event: 'quickPaySelected' });
    }

    const renderSelectedText = () => (
        <Box sx={Styles.SelectedText}>
          <CheckCircleOutline sx={Styles.CheckIcon} /> {t("SELECTED")}
        </Box>
    );

    const renderSelectButton = (option: string) => {
        if ((!isEFT && !isTPAY) || isExistingFactoring) {
            // Non-EFT or TPAY payment method or Factoring users can not switch to another payment option.
            return null;
        }

      return (
        <Button
          variant="contained"
          sx={Styles.CardBtn}
          data-test={`${option}-select-payment-option-button`}
          onClick={handleSelectButtonClick}
        >
          {t("SELECT")}
        </Button>
      );
    }

    const renderFaasButton = () => {
      if (isExistingFactoring) {
        return (
          <Button
            variant="outlined"
            sx={Styles.CardBtn}
            onClick={() => window.open(learnMoreLink, '_blank')}
            data-testid="faas-learn-more"
          >
            {t('LEARN_MORE')}
          </Button>
        );
      } else if (isFaasFactoring) {
        return renderSelectedText();
      } else {
        return (
          <Box sx={{width: '-webkit-fill-available'}}>
            <Button
              variant="contained"
              sx={Styles.CardBtn}
              onClick={() => setShowTriumphDialog(true)}
              data-testid="get-started-btn"
            >
              {t('GET_STARTED')}
            </Button>
            <Button
              variant="outlined"
              sx={Styles.CardBtn}
              onClick={() => window.open(learnMoreLink, '_blank')}
              data-testid="faas-learn-more"
            >
              {t('LEARN_MORE')}
            </Button>
          </Box>
        );
      }
    }

    const renderQuickPayButton = () => {
      if (isQuickPay) {
        return renderSelectedText()
      } else if (isStandardPay) {
        return renderSelectButton('quickpay')
      } else {
        return (
          <Button
            variant="outlined"
            sx={Styles.CardBtn}
            onClick={() => window.open('https://www.chrobinson.com/en-us/carriers/carrier-financial-services/quick-pay/', '_blank')}
            data-testid="quickpay-learn-more"
          >
            {t('LEARN_MORE')}
          </Button>
        );
      }
    }

    return (
      <Stack direction="column">
        <Typography variant="heading200">
          {t("FINANCIAL_SETTINGS")}
        </Typography>
        <Box sx={Styles.FinanceSettingsContainer} data-testid="finance-setting-section">
            <Typography variant="heading300">
                {t("PAYMENT_OPTION")}
            </Typography>
            <Box sx={Styles.PaymentOptionsSection}>
                {enableFaas &&
                  <Box sx={isFaasFactoring ? Styles.FaasContainerSelected : Styles.FaasCardContainer} data-testid="faas-card">
                    <Box sx={Styles.RecommendedIndicator}>
                      <ThumbUpAltOutlined fontSize="small"/>
                      {t("RECOMMENDED")}
                    </Box>
                    <Typography variant="h1" sx={Styles.FactoringHeader}>
                      {t('FACTORING_POWERED_BY_DELTA')}
                    </Typography>
                    <Box sx={isFaasFactoring ? Styles.FaasCardBodySelected : isExistingFactoring ? Styles.OneButtonBody : Styles.FaasCardBody}>
                      <Typography variant="body1">
                        {t('GET_PAID_IN_MINUTES')}
                      </Typography>
                    </Box>
                    {renderFaasButton()}
                  </Box>
                }
                {!isFaasFactoring &&
                  <Box sx={isQuickPay ? Styles.CardContainerSelected : Styles.CardContainer} data-testid="quickpay-card">
                    <Typography variant="h1" sx={Styles.PaymentOptionHeader}>
                      {t("QUICK_PAY")}
                    </Typography>
                    <Typography variant="body1" sx={isQuickPay ? Styles.CardBodySelected : Styles.CardBody}>
                        {t("GET_PAID_IN_TWO_DAYS")}
                    </Typography>
                    {renderQuickPayButton()}
                  </Box>
                }
                {!isFaasFactoring && 
                  <Box sx={isStandardPay ? Styles.CardContainerSelected : Styles.CardContainer}  data-testid="standard-card">
                    <Typography variant="h1" sx={Styles.PaymentOptionHeader}>
                      {t("STANDARD")}
                    </Typography>
                    <Typography variant="body1" sx={isStandardPay ? Styles.CardBodySelected : Styles.CardBody}>
                        {t("GET_PAID_IN_21_DAYS")}
                    </Typography>
                    {isStandardPay ? renderSelectedText() : renderSelectButton('standard')}
                  </Box>
                }
                {isExistingFactoring && 
                  <Box sx={Styles.CardContainerSelected} data-testid="factoring-card">
                    <Typography variant="h1" sx={Styles.PaymentOptionHeader}>
                      {t("EXISTING_FACTORING_PARTNER")}
                    </Typography>
                    <Typography variant="body1" sx={Styles.FactoringBodySelected}>
                        {t("YOU_ARE_CURRENTLY_BEING_FACILITATED")}
                    </Typography>
                    {renderSelectedText()}
                  </Box>
                }
            </Box>
            
            {isEFT &&
                <Box>
                    <Typography variant="heading400"> {t("PAYMENT_METHOD")}</Typography>
                    <Typography variant="body2"> {t("ELECTRIC_FUNDS_TRANSFER")}</Typography>
                </Box>
            }
            {enableFaas &&
              <Box sx={Styles.DisclaimerContainer} data-testid="faas-disclaimer">
                <Typography variant="body1" sx={{fontStyle: 'italic'}}>
                  {t('FACTORING_DISCLAIMER_LINE_1')}
                </Typography>
                <Typography variant="body1" sx={{fontStyle: 'italic'}}>
                  {t('FACTORING_DISCLAIMER_LINE_2')}
                </Typography>
              </Box>
            }
            {isQuickPay && <PaymentOptionDialogModal data-test="payment-option-dialog-Modal" />}
            {isQuickPay && <StandardPayUserAgreementModal onLegalAgreementSubmit={onLegalAgreementSubmit} isProcessingRequest={isProcessing}/>}
            {isEligibleForQuickPay && <QuickpayUserAgreementModal onLegalAgreementSubmit={onLegalAgreementSubmit} isProcessingRequest={isProcessing}/>}
            {enableFaas && <TriumphSignupDialog showDialog={showTriumphDialog} setShowDialog={setShowTriumphDialog} userId={userId} contactId={contactId} />}
        </Box>
      </Stack>
    );
}
