import { Inject, Service } from 'typedi';
import { BaseRepository } from 'app/repositories/base.repository';
import { Observable } from 'rxjs';

@Service()
export class PreferencesRepository extends BaseRepository {
  @Inject('apiConfig.preferencesAPI')
  protected baseUrl: string;

  public getCommunicationPreferences(contactId: string): Observable<PreferenceNotificationsResponse> {
    return this.get(`/users/contacts/${contactId}/communicationpreferences`).map(response => response);
  }

  public updateCommunicationPreferences(request: PreferenceNotificationsRequest) {
    return this.put(`/users/communicationpreferences`, request);
  }
}
