import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { NastRoles, TMCRoles, VendorRoles } from '../enums/CompanyInformationEnum';
import { Dispatch, SetStateAction, useState } from 'react';
import * as Styles from 'features/account-settings/manage-users';

export interface RoleAndPermissionsRadioProps {
  role: string;
  isChecked: boolean;
  handleChange: () => void;
}

export const RoleAndPermissionsRadio = ({ role, isChecked, handleChange }) => {
  const { t } = useTranslation();

  const roleTitleKeys = {
    [NastRoles.OWNER_OPERATOR]: 'OWNER_OPERATOR',
    [NastRoles.MANAGER]: 'MANAGER',
    [NastRoles.DISPATCHER]: 'DISPATCHER',
    [NastRoles.DRIVER]: 'DRIVER_LCASE',
    [NastRoles.AR]: 'ACCOUNTS_RECEIVABLE',
    [TMCRoles.TMC_DOMESTIC]: 'TMC_DOMESTIC',
    [TMCRoles.TMC_DOCK_MANAGER]: 'TMC_DOCK_MANAGER',
    [TMCRoles.TMC_GLOBAL]: 'TMC_GLOBAL',
    [TMCRoles.TMC_LIMITED_USER]: 'TMC_LIMITED',
    [VendorRoles.FACTORING_COMPANY]: 'FACTORING_COMPANY'
  };
  
  const roleDescriptionKeys = {
    [NastRoles.OWNER_OPERATOR]: 'OWNER_OPERATOR_DESCRIPTION',
    [NastRoles.MANAGER]: 'MANAGER_DESCRIPTION',
    [NastRoles.DISPATCHER]: 'DISPATCHER_DESCRIPTION',
    [NastRoles.DRIVER]: 'DRIVER_LCASE_DESCRIPTION',
    [NastRoles.AR]: 'ACCOUNTS_RECEIVABLE_DESCRIPTION',
    [TMCRoles.TMC_DOMESTIC]: 'TMC_DOMESTIC_DESCRIPTION',
    [TMCRoles.TMC_DOCK_MANAGER]: 'TMC_DOCK_MANAGER_DESCRIPTION',
    [TMCRoles.TMC_GLOBAL]: 'TMC_GLOBAL_DESCRIPTION',
    [TMCRoles.TMC_LIMITED_USER]: 'TMC_LIMITED_DESCRIPTION',
    [VendorRoles.FACTORING_COMPANY]: 'FACTORING_COMPANY_DESCRIPTION'
  };

  const rolePermissionKeys = {
    [NastRoles.OWNER_OPERATOR]: ['BOOK_LOADS', 'MANAGE_MY_LOADS', 'COMMITTED_LOAD_TENDERS', 'PAYMENTS_', 'VIEW_CLAIMS'],
    [NastRoles.MANAGER]: ['BOOK_LOADS', 'MANAGE_MY_LOADS', 'COMMITTED_LOAD_TENDERS', 'PAYMENTS_', 'VIEW_CLAIMS'],
    [NastRoles.DISPATCHER]: ['BOOK_LOADS', 'MANAGE_MY_LOADS', 'COMMITTED_LOAD_TENDERS'],
    [NastRoles.DRIVER]: ['LOADS_ASSIGNED_TO_ME'],
    [NastRoles.AR]: ['PAYMENTS_', 'VIEW_CLAIMS'],
    [TMCRoles.TMC_DOMESTIC]: ['MANAGE_MY_LOADS', 'TMC_TENDERS', 'VIEW_RATES', 'SPOT_BIDS', 'ACCOUNTS_RECEIVABLE', 'MANAGE_INVOICES', 'VIEW_CLAIMS'],
    [TMCRoles.TMC_DOCK_MANAGER]: ['MANAGE_MY_LOADS', 'TMC_TENDERS', 'VIEW_RATES', 'SPOT_BIDS', 'ACCOUNTS_RECEIVABLE', 'MANAGE_INVOICES', 'VIEW_CLAIMS', 'DOCK_MANAGER_ADMIN'],
    [TMCRoles.TMC_GLOBAL]: ['MANAGE_MY_LOADS', 'TMC_TENDERS', 'VIEW_RATES', 'SPOT_BIDS', 'MY_SHIPMENTS', 'ACCOUNTS_RECEIVABLE', 'MANAGE_INVOICES', 'VIEW_CLAIMS', 'DOCK_MANAGER_ADMIN'],
    [TMCRoles.TMC_LIMITED_USER]: ['MANAGE_MY_LOADS', 'TMC_TENDERS', 'VIEW_RATES', 'SPOT_BIDS'],
    [VendorRoles.FACTORING_COMPANY]: ['PAYMENTS_', 'VIEW_CLAIMS']
  }

  const displayPermissions = (role) => {
    return rolePermissionKeys[role].map(permission =>
      <Typography variant='subtitle400' sx={Styles.Permission} key={`${role}-${permission}`}>
        {t(permission)}
      </Typography>
    );
  }

  return (
    <FormControlLabel
      value={role}
      onChange={() => handleChange(role)}
      checked={isChecked}
      control={<Radio sx={Styles.RadioInput} data-testid={`${role}-radio-btn`}/>}
      data-testid={isChecked ? 'role-selection-checked' : 'role-selection'}
      sx={isChecked ? Styles.SelectedRadioButton : Styles.RadioButton}
      label={
        <div className="label-container">
          <Typography variant="subtitle1" sx={isChecked ? Styles.SelectedRadioHeader : Styles.RadioHeader}>
            {t(roleTitleKeys[role])}
          </Typography>
          <Typography variant="body1" sx={Styles.RoleBody}>
            {t(roleDescriptionKeys[role])}
          </Typography>
          <Stack direction='row' spacing={1} sx={Styles.PermissionsStack}>
            <Typography variant='subtitle400' sx={Styles.AccessTo}>
              {t('ACCESS_TO')}
            </Typography>
            {displayPermissions(role)}
          </Stack>
        </div>
      }
	/>
  );
}