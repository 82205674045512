import React from 'react';
import classNames from 'classnames';
import { Container } from 'typedi';
import { ChangeEvent, FormEvent } from 'react';

import { Button, Label, Option } from 'shared/components/elements/elements.components';
import { useTranslation } from 'react-i18next';
import { DataLabelPair } from 'shared/components/formatters/data-label-pair.component';
import { ComponentConnectorFactory, ResourceProps } from 'store/component-connector';
import {
  CHANGE_EMAIL_MODAL,
  ChangeEmailModal,
  CHANGE_PASSWORD_MODAL,
  ChangePasswordModal,
  AccountForm
} from 'features/account-settings';
import { TimeFormat } from 'shared/enums/time-format.enum';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';
import { AuthRepository } from 'app/repositories/auth.repository';
import { UserRepository } from 'app/repositories/user.repository';
import { User } from 'shared/models/user.model';
import { Culture } from 'app/i18n/culture.actions';
import { select } from 'store/selectors/state.selectors';
import { hideModal, HideModalAction, showModal, ShowModalAction } from 'shared/components/modal/modal.actions';
import { Select } from 'shared/components/form-fields/select.component';
import { ToastManager } from 'shared/components/toast/toast.actions';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { TimeZoneSelect } from 'shared/components/timezone-select/timezone-select.component';
import { hideLoader, showLoader } from 'shared/components/loading/loading.actions';
import { completeLogin } from 'features/security/auth.actions';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { useIsPaymentsUser } from 'pages/payments/hooks/use-is-payments-user.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import '../styles/account-form.component.scss';

const withHooks = (OriginalComponent: React.ComponentType<Props>) => props => {
  const isPaymentsUser = useIsPaymentsUser();
  const { isOktaEnabled, webPreferencesNotificationsBraze } = useFlags();
  const { t } = useTranslation();

  return (
    <OriginalComponent
      isPaymentsUser={isPaymentsUser}
      isOktaEnabled={isOktaEnabled}
      t={t}
      {...props as Props} />
  );
}
interface OwnProps {
  onSubmit(request: UserPatchRequest, successCallback?: Function, errorCallback?: (error: APIErrorResponse) => any): void;
  userStartPages: string[];
  isPaymentsUser: Boolean;
  isOktaEnabled: Boolean;
  t: Function;
}

interface ConnectStateProps {
  user: User;
  culture: Culture;
  carrier: CarrierDetail;
}

interface ConnectDispatchProps {
  showEmailModal: () => ShowModalAction;
  hideEmailModal: () => HideModalAction;
  showPasswordModal: () => ShowModalAction;
  hidePasswordModal: () => HideModalAction;
  showLoader: (name?: string) => void;
  hideLoader: (name?: string) => void;
  completeLogin: (userJSON: UserJSON, carrierDetail: CarrierDetail) => void;
}

interface State {
  displaySavedSpan: Boolean;
  countries: GlobalizationCountry[];
}

type ConnectResourceKeys = 'SELECT_COUNTRY' | 'SELECT_LANGUAGE';

type Props = OwnProps & ConnectStateProps & ResourceProps<ConnectResourceKeys> & ConnectDispatchProps;

export class AccountFormContainer extends React.Component<Props> {
  state: State = {
    displaySavedSpan: false,
    countries: [],
  };

  form: AccountForm;
  toasts = Container.get(ToastManager);
  private refDataRepo = Container.get(ReferenceDataRepository);
  private authRepo = Container.get(AuthRepository);
  private userRepo = Container.get(UserRepository);
  private userStartPages = this.getUserStartPages();

  UNSAFE_componentWillMount() {
    const { user, culture, isPaymentsUser } = this.props;
    this.getCountries();
    this.form = new AccountForm(user, this.userStartPages, culture);
    this.form.changes.subscribe(() => this.forceUpdate());
    // if users previous start page setting value was accounts receivable, and they are a payments page user then update their default page
    if (user.properties?.startPage === "ACCOUNT_RECEIVABLE" && isPaymentsUser) {
      this.form.get('startPage').setValue('PAYMENTS');
      this.props.onSubmit(
        this.form.toUserPatchRequest()
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>) {
    if (nextProps.culture && nextProps.culture !== this.props.culture) {
      this.form.getCultureForm().setCulture(nextProps.culture);
    }
  }

  getUserStartPages() {
    let startPages = this.authRepo.getPotentialUserStartPages(this.props.user);
    startPages = this.props.isPaymentsUser ? startPages.filter(x => x !== "ACCOUNT_RECEIVABLE") : startPages.filter(x => x !== "PAYMENTS");
    startPages.sort();
    return startPages;
  }

  onAccountFormSubmit = (request: UserPatchRequest, callback?: Function, errorCallback?: (err: APIErrorResponse) => any) => {
    this.props.showLoader();
    this.userRepo.updateUserProperties({
      ...request,
      userId: this.props.user.userId,
    })
      .do(() => callback && callback())
      .finally(this.props.hideLoader)
      .subscribe(
        json => this.props.completeLogin(json, this.props.carrier),
        err => {
          this.onUpdateUserPropertyError(err);
          if (errorCallback && typeof errorCallback === 'function') {
            errorCallback(err);
          }
        },
      );
  };

  onUpdateUserPropertyError = (err: APIErrorResponse) => {
    if (err instanceof APIErrorResponse && err.includes('E1030108C2A5')) { // incorrect password
      return;
    }
    this.toasts.error([
      this.props.t("CANNOT_PROCESS_YOUR_REQUEST_AT_THIS_TIME_PLEASE_TR"),
      ...err.errors?.map(error => error.message)
    ]);
  };

  onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({displaySavedSpan: false});

    if (!this.form.valid) {
      this.form.touch();
      return;
    }

    this.onAccountFormSubmit(
      this.form.toUserPatchRequest(),
      // display saved message on success
      () => this.setState({displaySavedSpan: true})
    );
  };

  getCountries = () => {
    this.refDataRepo.getGlobalizationLocales()
      .subscribe((countries) => this.setState({countries}));
  };

  onLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const localeDisplayName = e.currentTarget.value;
    const currentSelectedCountry = this.form.get('culture.country').value;
    const selectedLanguage = currentSelectedCountry.locales.find(country => country['displayName'] === localeDisplayName);
    this.form.get('culture.locale').setValue(selectedLanguage);
  };

  onCurrencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.form.get('preferredCurrencyCode').setValue(e.currentTarget.value);
  };

  onCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const countryName = e.currentTarget.value;
    const selectedCountry = this.state.countries.find(country => country['name'] === countryName);
    this.form.get('culture').get('country').setValue(selectedCountry);
    if (selectedCountry.code && selectedCountry.locales.length === 1) {
      this.form.get('culture.locale').setValue(selectedCountry.locales[0]);
      this.form.get('culture.locale').disable();
    } else {
      this.form.get('culture.locale').enable();
    }
  };
  onTimeFormatChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.form.get('timeFormat').setValue(Number(e.target.value));
  };

  onUnitOfMeasureChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.form.get('unitOfMeasure').setValue(Number(e.target.value));
  };

  onChangeEmailSubmit = (emailAddress: string) => {
    this.props.hideEmailModal();
    this.props.onSubmit({emailAddress}, () => this.toasts.success([this.props.t("YOUR_EMAIL_ADDRESS_HAS_BEEN_UPDATED")]));
  };

  onChangePasswordSubmit = (request: UserPatchRequest, onSuccess?, onFailure?) => {
    this.props.onSubmit(request, () => {
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess();
      }
      this.props.hidePasswordModal();
      this.toasts.success([this.props.t("YOUR_PASSWORD_HAS_BEEN_UPDATED")]);
    }, onFailure);
  };

  onTimeZoneChange = (timeZone: string) => {
    this.form.get('timeZone').setValue(timeZone);
  };

  render() {
    if (!this.form) {
      return null;
    }
    const countryField = this.form.get('culture.country');
    const localeField = this.form.get('culture.locale');
    const countryList = this.state.countries;
    const timeZoneField = this.form.get('timeZone');
    const currencyCode = this.form.get('preferredCurrencyCode');
    const currencies = [
      { id: 1, code: 'USD', value: 'US Dollar ($ - USD)'},
      { id: 2, code: 'CAD', value: 'Canadian Dollar (C$ - CAD)'}]

    return (
      <Stack direction="column">
        <Typography variant="heading200">
          {this.props.t("PROFILE_SETTINGS")}
        </Typography>
        <div className="ns-card content-card shadow space-outer-top-md profile-settings">
          <h2>{this.props.t("ACCOUNT")}</h2>
          {!this.props.isOktaEnabled &&
            <>
              <div className="space-outer-top-md">
                <DataLabelPair id="change-email-current-value" className="display-inline-block space-outer-right-sm" isSensitive={false} label="EMAIL_ADDRESS" data={this.props.user.email} />
                <Button btnLink id="change-user-email-link" track onClick={this.props.showEmailModal} resource="CHANGE" />
              </div>
              <div className="space-outer-top-md">
                <div className="data-label-pair">
                  <Label className="display-block" htmlFor="settings_password" resource="PASSWORD" />
                  <Button btnLink onClick={this.props.showPasswordModal} type="button" id="settings_password-link" track resource="CHANGE" />
                </div>
              </div>
            </>
          }
          <form id="account-form" onSubmit={this.onSubmit}>
          <div className="row culture-select-form account-form-first-row">
            <div className={classNames('form-group', 'col-sm-3', 'col-md-6', 'col-lg-3', 'js-field-country', {'has-error': countryField.hasErrors()})}>
              <label htmlFor="country">
                  {this.props.t("COUNTRY")}
              </label>
              <select
                id="select-country"
                value={countryField.value?.name || 'United States'}
                onChange={this.onCountryChange}
                name="country"
                className="select-country form-control"
              >
                {countryList.length > 1 && countryList.map((country) =>
                  <option value={country.name} key={country.code}>{country.name}</option>
                )}
              </select>
            </div>
            <div
              className={classNames('form-group', 'col-sm-3', 'col-md-6', 'col-lg-3', 'js-field-locale', {'has-error': localeField.hasErrors() && !localeField.disabled})}>
                <label htmlFor="locale">
                  {this.props.t("LANGUAGE")}
                </label>
                <select
                  id="select-country-language"
                  className="select-country-language form-control"
                  name="locale"
                  onChange={this.onLanguageChange}
                  value={localeField?.value?.displayName || 'English'}
                  disabled={countryField.value?.locales && (countryField.value.locales.length < 2)}
                >
                  {countryField.value?.locales?.map(locale =>
                    <option value={locale.displayName} key={locale.locale}>{locale.displayName}</option>
                  )}
                </select>
            </div>
            <div
                className='form-group col-sm-3 col-md-6 col-lg-3 js-field-currency'>
                <label htmlFor="currency">
                  {this.props.t("CURRENCY")}
                </label>
                <select
                  id="select-currency"
                  className="select-currency form-control"
                  name="currency"
                  onChange={this.onCurrencyChange}
                  value={currencyCode.value}
                >
                  {currencies.map(currency => <option value={currency.code} key={currency.id}>{currency.value}</option>)}
                </select>
            </div>
            <div className="col-sm-2 col-md-3 col-lg-2">
                <fieldset>
                  <legend><label className="h3">{this.props.t("MEASUREMENTS")}</label></legend>

                  <label className="radio-inline" htmlFor="settings_measurements_standard">
                    <input
                      type="radio"
                      name="settings_measurements"
                      id="settings_measurements_standard"
                      value={UnitOfMeasure.US}
                      checked={this.form.value.unitOfMeasure === UnitOfMeasure.US}
                      onChange={this.onUnitOfMeasureChange}
                    />{this.props.t("STANDARD")}
                  </label>
                  <label className="radio-inline" htmlFor="settings_measurements_metric">
                    <input
                      type="radio"
                      name="settings_measurements"
                      id="settings_measurements_metric"
                      value={UnitOfMeasure.Metric} // metric
                      checked={this.form.value.unitOfMeasure === UnitOfMeasure.Metric}
                      onChange={this.onUnitOfMeasureChange}
                    />{this.props.t("METRIC")}
                  </label>
                </fieldset>
              </div>
          </div>
          <div className="row culture-select-form">
            <div className="col-sm-3 col-md-6 col-lg-3">
              <Label className="space-outer-top-md" htmlFor="settings_default-start-page" resource="DEFAULT_START_PAGE"/>
              <Select
                id="settings_default-start-page"
                field={this.form.get('startPage')}
              >
                  {this.userStartPages.map((startPage, i) => {
                    if (startPage === 'CHRW_TENDERS' || startPage === 'TMC_TENDERS') {
                      return <Option key={i} value={startPage} resource="TENDERS" />
                    }
                    if (startPage === 'PAYMENTS') {
                      return <Option key={i} value={startPage} resource="PAYMENTS_" />
                    }
                    else {
                      return <Option key={i} value={startPage} resource={startPage as ResourceKey} />
                    }
                  })}
              </Select>
            </div>
            <div className="col-sm-3 col-md-6 col-lg-3 time-zone-select">
              <TimeZoneSelect timezone={timeZoneField && timeZoneField.value || 'Central America Standard Time'} onChange={this.onTimeZoneChange}/>
            </div>
            <div className="col-sm-2 col-md-3 col-lg-2 time-format-radio">
              <fieldset>
                <legend><Label className="h3" resource="TIME_FORMAT"/></legend>

                <label className="radio-inline" htmlFor="settings_time-format_12-hour">
                  <input
                    type="radio"
                    name="settings_time-format"
                    id="settings_time-format_12-hour"
                    checked={this.form.value.timeFormat === TimeFormat.TwelveHour}
                    value="12"
                    onChange={this.onTimeFormatChange}
                  />{this.props.t("12_HOUR")}
                </label>
                <label className="radio-inline" htmlFor="settings_time-format_24-hour">
                  <input
                    type="radio"
                    name="settings_time-format"
                    id="settings_time-format_24-hour"
                    checked={this.form.value.timeFormat === TimeFormat.TwentyFourHour}
                    value="24"
                    onChange={this.onTimeFormatChange}
                  />{this.props.t("24_HOUR")}
                </label>
              </fieldset>
            </div>
          </div>
            <div className="space-outer-top-lg">
              <Button id="save-user-settings-button" track type="submit" className="space-outer-right-md" btnPrimary mobileResponsive resource="SAVE"/>
              {this.state.displaySavedSpan
                ? <span id="account-form-saved-span" className="saved-text mobile-block space-outer-top-sm text-center">
                    <span className="glyphicon glyphicon-ok"/>
                    {this.props.t("SAVED")}
                  </span>
                : null
              }
            </div>
          </form>
          <ChangeEmailModal
            onSubmit={this.onChangeEmailSubmit}
            onCancel={this.props.hideEmailModal}
          />
          <ChangePasswordModal
            onSubmit={this.onChangePasswordSubmit}
            onCancel={this.props.hidePasswordModal}
          />
        </div>
      </Stack>
    );
  }
}

export const AccountFormComponent = ComponentConnectorFactory<OwnProps, ConnectStateProps, ConnectDispatchProps, ConnectResourceKeys>()
  .combineStateSelectors(
    select.auth.user,
    select.culture,
    select.auth.carrier
  )
  .withResources<ConnectResourceKeys>('SELECT_COUNTRY', 'SELECT_LANGUAGE')
  .mapDispatchToProps({
    showEmailModal: () => showModal(CHANGE_EMAIL_MODAL),
    hideEmailModal: () => hideModal(CHANGE_EMAIL_MODAL),
    showPasswordModal: () => showModal(CHANGE_PASSWORD_MODAL),
    hidePasswordModal: () => hideModal(CHANGE_PASSWORD_MODAL),
    showLoader,
    hideLoader,
    completeLogin
  })
  .connect(withHooks(AccountFormContainer));
