export const RemoveDialogTitleContainer = {
 display: 'flex',
 gap: '16px',
 alignItems: 'center',
 padding: '16px 24px',
};

export const RemoveDialogTitle = {
 color: '#212121',
 fontSize: '18px',
 fontWeight: '400',
 lineHeight: '24px',
 letterSpacing: '0.15px',
};

export const RemoveDialogCloseButton = {
 color: '#212121',
};

export const RemoveDialogBody = {
 color: '#212121',
 fontSize: '14px',
 fontWeight: '400',
 lineHeight: '24px',
 letterSpacing: '0.5px',
};

export const RemoveDialogActions = {
 padding: '24px',
};

export const CircularProgressIcon = {
 color: 'rgba(0, 0, 0, 0.28)',
};
