export enum PaymentOptionType {
    None = 0,
    QuickPay = 1,
    StandardPay = 2,
    Factoring = 3,
}

export enum PaymentMethodType {
  EFT = 1,
  TPAY = 15
}