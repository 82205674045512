export const PhoneInput = {
 width: '100%',
 '.PhoneInputCountry': {
  marginRight: '0',
  border: '1px solid lightgrey',
  width: '75px',
  height: '56px',
  justifyContent: 'space-between',
  padding: '12px',
  borderRadius: '4px 0 0 4px',
  backgroundColor: 'white',

  '&:hover': {
   backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
 },
 '.PhoneInputInput': {
  height: '56px',
  padding: '18px 14px',
  fontSize: '16px',
  border: '1px lightgray solid',
  borderRadius: '0 4px 4px 0',

  '&:hover': {
   borderColor: '#000000',
  },

  '&:focus': {
   outlineColor: '#0071c7 !important',
  },
 },
 '#phone-number-error': {
  borderColor: '#CD0202',
  borderWidth: '1px',
 },
 '.helperText': {
  color: '#C20000',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '400',
  fontSize: '0.75rem',
  lineHeight: '1.66',
  letterSpacing: '0.03333em',
  textAlign: 'left',
  marginTop: '3px',
  marginRight: '14px',
  marginBottom: '0',
  marginLeft: '14px',
 },
};

export const TextField = {
 backgroundColor: '#FFFFFF',
};
