export const FinanceSettingsContainer = {
  padding: '20px',
  border: '1px solid #cccccc',
  backgroundColor: '#ffffff',
  width: 'fit-content',
  marginTop: '16px'
}

export const PaymentOptionsSection = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap'
}

export const CompareOptionsBtn = {
  width: {
	xs: '310px',
	md: 'fit-content'
  },
  marginTop: {
	xs: '16px',
	md: '0px'
  }
}

export const DisclaimerContainer = {
  padding: '16px 0px',
}

export const InfoIcon = {
	marginTop: '2px !important'
}

export const CardContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  width: '285px',
  height: {
	xs: 'fit-content',
	sm: '392px'
  },
  borderRadius: '4px',
  textAlign: 'center',
  margin: {
	  xs: '0.5rem auto',
	  sm: '1rem 1rem 1rem 0rem'
  },
  padding: '20px 16px',
  border: '1px solid #cccccc',
  boxShadow: 'none',
  transition: '0.3s',
  backgroundColor: '#ffffff'
}

export const CardContainerSelected = {
  ...CardContainer,
  background: '#F1F8FF',
  border: '2px solid #0071C7'
}

export const FaasCardContainer = {
  ...CardContainer,
  width: {
	xs: '285px',
	md: '435px'
  }
}

export const FaasContainerSelected = {
  ...FaasCardContainer,
  background: '#F1F8FF',
  border: '2px solid #0071C7'
}

export const RecommendedIndicator = {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
  borderRadius: '4px',
  textAlign: 'center',
  padding: '4px 8px',
  fontWeight: '600',
  gap: '4px',
  background: '#F3FFF3',
  color: '#005C05',
  border: '1px solid #005C05',
  height: '32px'
}

export const FactoringHeader = {
	paddingTop: '10px'
}

export const FaasCardBody = {
  paddingTop: '12px',
  paddingBottom: {
	xs: '0px',
	md: '44px'
  }
}

export const OneButtonBody = {
  ...FaasCardBody,
  paddingBottom: {
	xs: '48px',
	md: '94px'
  }
}

export const FaasCardBodySelected = {
  ...FaasCardBody,
  paddingBottom: '120px'
}

export const CardBtn = {
  marginTop: '12px',
  width: '-webkit-fill-available',
}

export const SelectedText = {
  display: 'flex',
  justifyContent: 'space-between',
  color: '#008015'
}

export const CheckIcon = {
  height: '24px',
  width: '24px',
  marginRight: '4px'
}

export const CardBodySelected = {
  marginBottom: {
	xs: '12px',
	sm: '164px'
  }
}

export const CardBody = {
  marginBottom: {
	xs: '0px',
	sm: '144px'
  }
}

export const PaymentOptionHeader = {
  paddingTop: '40px',
  paddingBottom: '20px'
}

export const FactoringBodySelected = {
  marginBottom: {
	xs: '12px',
	sm: '134px'
  }
}