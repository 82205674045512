import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import * as Styles from 'features/account-settings/manage-users/styles';

const SkeletonCell = () => (
  <TableCell>
    <Skeleton animation="wave" variant="rounded" />
  </TableCell>
);

export const SkeletonTableRows = () => {
  return (
    <>
      {[...Array(7)].map((_, index) => (
        <TableRow key={index} sx={Styles.TableRow}>
          <SkeletonCell />
          <SkeletonCell />
          <SkeletonCell />
          <SkeletonCell />
          <SkeletonCell />
          <SkeletonCell />
          <SkeletonCell />
        </TableRow>
      ))}
    </>
  );
};
