import * as v from 'app/forms/control/form-validators';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { groupStructure } from 'app/forms/structure/form-group.structure';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { ValidationFailureReason } from '@shared/enums/validation-failure-reason.enum';

interface ChangePasswordFormValues {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export class ChangePasswordForm extends NavCarrierFormGroup<ChangePasswordFormValues> {

  constructor(username) {
    super(groupStructure({
      password: fieldStructure('', {validators: [v.required]}),
      newPassword: fieldStructure('', {
        validators: [
          v.required,
          v.minLength(6),
          v.maxLength(12),
          v.pattern(/[a-z]+/i, 'oneLetter'),
          v.pattern(/[0-9]+/, 'oneNumber'),
        ]
      }),
      confirmPassword: fieldStructure('', {validators: [v.required]}),
    }));
    this.get('newPassword').addValidator(v.mustNotContain(username, true));
    this.get('confirmPassword').addValidator(v.matchField(this.get('newPassword')));
    this.get('password').addValidator(v.createValidator('invalidPassword', () => !this.hasServerError(null, 1030108 as ValidationFailureReason)));
  }

  toPasswordUpdateRequest(): UserPatchRequest {
    return ({
      oldPassword: this.value.password,
      password: this.value.newPassword
    });
  }
}
