import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { FilterPhoneField, FilterStatusField, FilterTextField, useFilterOptions } from 'features/account-settings';
import * as Styles from 'features/account-settings/manage-users/styles';

interface OnSearchProps {
 searchType: MembershipSearchType;
 searchText: string;
}

interface FilterFormProps {
 isLoading: boolean;
 onSearch: ({ searchType, searchText }: OnSearchProps) => void;
 onClear: () => void;
}

export const FilterValueFieldName = Object.freeze({
 Email: 'Email',
 Name: 'Name',
 Phone: 'Phone',
 Status: 'Status',
});

export const SearchByDisplayValue = Object.freeze({
 EMAIL: 'Email',
 NAME: 'Name',
 PHONE_NUMBER: 'Phone number',
 STATUS: 'Status',
});

export const FilterForm = ({ onSearch, onClear, isLoading }: FilterFormProps) => {
 const { t } = useTranslation();
 const { filterOptions, setFilterOptions } = useFilterOptions();
 const selectedOption = filterOptions.find(c => c.isSelected);

 const handleSearchByChange = useCallback(
  (e: any) => {
   const value = e.target.value;
   setFilterOptions(filterOptions.map(c => ({ ...c, isSelected: c.displayValue === value, value: '' })));
  },
  [filterOptions, setFilterOptions]
 );

 const handleSearch = () => {
  const { validator, value, searchType } = selectedOption;

  if (validator) {
   const { valid, message: errorMessage } = validator(value);

   if (!valid) {
    return setFilterOptions(filterOptions.map(c => (c.isSelected ? { ...c, errorMessage: t(errorMessage) } : { ...c, value: '', errorMessage: '' })));
   }
  }

  onSearch({ searchType, searchText: value.trim() });
 };

 const handleOnChange = useCallback(
  (value: string) => {
   setFilterOptions(filterOptions.map(c => (c.isSelected ? { ...c, value, errorMessage: '' } : { ...c, value: '', errorMessage: '' })));
  },
  [filterOptions, setFilterOptions]
 );

 const handleOnClear = useCallback(() => {
  // Reset filters to default Email type and clear values
  setFilterOptions(filterOptions.map(c => ({ ...c, isSelected: c.name === SearchByDisplayValue.EMAIL, value: '', errorMessage: '' })));
  onClear();
 }, [filterOptions, setFilterOptions]);

 return (
  <Box sx={Styles.Form}>
   <Box sx={Styles.SearchBy}>
    <Typography variant="h4" data-testid="title">
     {t('SEARCH_BY')}
    </Typography>
   </Box>
   <Box sx={Styles.FiltersWrapper}>
    <Select data-testid="search-by" fullWidth value={selectedOption.displayValue} onChange={handleSearchByChange} sx={Styles.FilterSelect}>
     {filterOptions.map(c => (
      <MenuItem key={c.displayValue} value={c.displayValue}>
       <Typography variant="h4">{c.displayValue}</Typography>
      </MenuItem>
     ))}
    </Select>
    {(selectedOption.name === FilterValueFieldName.Name || selectedOption.name === FilterValueFieldName.Email) && (
     <FilterTextField
      value={selectedOption.value}
      placeholder={selectedOption.placeholder}
      onChange={handleOnChange}
      errorMessage={selectedOption.errorMessage}
     />
    )}
    {selectedOption.name === FilterValueFieldName.Phone && (
     <FilterPhoneField value={selectedOption.value} onChange={handleOnChange} errorMessage={selectedOption.errorMessage} />
    )}
    {selectedOption.name === FilterValueFieldName.Status && (
     <FilterStatusField value={selectedOption.value} options={selectedOption.options} onChange={handleOnChange} />
    )}
    <Button data-testid="search-button" disabled={isLoading} variant="contained" color="primary" onClick={handleSearch} sx={Styles.SearchButton}>
     {t('SEARCH')}
    </Button>
    <Button
     data-testid="clear-button"
     disabled={!selectedOption.value}
     variant="outlined"
     color="primary"
     onClick={handleOnClear}
     sx={Styles.ClearButton}
    >
     {t('CLEAR')}
    </Button>
   </Box>
  </Box>
 );
};
