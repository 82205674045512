import { SxProps } from '@mui/system';

export const StatusContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '922px',
  gap: '16px',
  alignContent: 'space-between',
  marginBottom: '16px',
};

export const RadioGroupStyles: SxProps = {
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
};

export const RadioBtn: SxProps = {
  '&.MuiRadio-root': {
    color: '#212121'
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .PrivateSwitchBase-input': {
    position: 'absolute'
  }
}

export const FormControlLabelActiveStyles = (isActive: boolean): SxProps => ({
  width: {
    lg: '447px',
  },
  minHeight: '88px',
  alignItems: 'center',
  alignSelf: 'stretch',
  marginRight: {
    lg: '10px',
  },
  marginBottom: {
    xs: '10px',
    lg: '0px',
  },
  gap: '32px',
  border: isActive ? '2px solid #0071C7' : '1px solid rgba(0, 0, 0, 0.26)',
  borderRadius: '8px',
  background: isActive ? '#F1F8FF' : 'none',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
});

export const FormControlLabelSuspendedStyles = (isDisabled: boolean): SxProps => ({
  width: {
    lg: '447px',
  },
  minHeight: '88px',
  alignItems: 'center',
  alignSelf: 'stretch',
  marginRight: 0,
  gap: '32px',
  border: isDisabled ? '2px solid #0071C7' : '1px solid rgba(0, 0, 0, 0.26)',
  background: isDisabled ? '#F1F8FF' : 'none',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
});

export const ActiveIconWrapper: SxProps = {
  display: 'flex',
  color: '#005C05',
};

export const ActiveTextStyles: SxProps = {
  color: '#005C05',
  marginLeft: '6px',
  fontSize: '14px',
  fontWeight: '600',
};

export const ActiveDescriptionStyles: SxProps = {
  color: '#63676A',
  fontSize: '12px',
  marginTop: '4px',
};

export const SuspendedIconWrapper: SxProps = {
  display: 'flex',
  color: '#B54D08',
};

export const SuspendedTextStyles: SxProps = {
  marginLeft: '6px',
  color: '#B54D08',
  fontSize: '14px',
  fontWeight: '600',
};

export const SuspendedDescriptionStyles: SxProps = {
  color: '#63676A',
  fontSize: '12px',
  marginTop: '4px',
};
