import { useState, Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AddUserViews, MembershipStatus, ManageUsersViews, useDisplayRolesForOrgType }  from 'features/account-settings';
import { SeverityType, TextInput, useSnackbar } from '@chr/eds-react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@features/okta/components/Buttons';
import { postUser } from 'api/userManagement';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import * as Styles from 'features/account-settings/manage-users';

export interface UserInformationProps {
  email: string;
  setAddUserView: Dispatch<SetStateAction<AddUserViews>>;
  setManageUsersView: Dispatch<SetStateAction<symbol>>;
}

export const UserInformation = ({ email, setAddUserView, setManageUsersView }: UserInformationProps) => {
  const { t } = useTranslation();
  const [role, setRole] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectRoleError, setSelectRoleError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const snackbar = useSnackbar();
  const activeMembership = useSelector(state => state.userManagement?.activeMembership);
  const organizationId = activeMembership?.organizationId;
  const companyCode = activeMembership?.companyCode;
  const organization = useSelector(state => state.userManagement?.organization);

  const handleSubmission = () => {
    if (!role) {
      setSelectRoleError(true);
    } else {
      setIsLoading(true);
      const postUserRequest = {
        email: email,
        membership: {
          organizationId: organizationId,
          status: MembershipStatus.ACTIVE,
          roles: [role],
          isAdmin: isAdmin,
          isDefault: false,
          companyCode: companyCode
        }
      }

      postUser(postUserRequest)
        .then(() => handleSuccess())
        .catch(() => handleFailure())
        .finally(() => setIsLoading(false))
    }
  }

  const handleSuccess = () => {
    setManageUsersView(ManageUsersViews.MANAGE_USERS);
    snackbar.enqueueSnackbar({
      id: 'add-user-success',
      message: t('NEW_USER_ADDED'),
      severity: 'success' as SeverityType
    });
  }
  const handleFailure = () => {
    snackbar.enqueueSnackbar({
      id: 'add-user-failure',
      message: t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN'),
      severity: 'error' as SeverityType,
    });
  }

  const handleRoleUpdate = (role) => {
    setRole(role);
    setSelectRoleError(false);
  }

  return (
	<>
	  <Box sx={Styles.UserInformationContainer}>
        <Typography variant="heading300">
          {t('NEW_USERS_INFORMATION')}
        </Typography>
        <Box sx={Styles.EmailInput}>
          <TextInput
            label={t('EMAIL_ADDRESS')}
            value={email}
            onChange={() => { }}
            disabled
          />
        </Box>
        <Typography variant="heading400">
          {t('ROLE_AND_PERMISSIONS')}
        </Typography>
        {useDisplayRolesForOrgType(organization, role, handleRoleUpdate)}
        {selectRoleError && 
          <Stack spacing={1} direction="row" sx={Styles.RoleErrorContainer} data-testid='role-selection-error'>
            <ErrorOutlineIcon fontSize="small"/>
            <Typography variant="body200">
              {t('YOU_MUST_SELECT_A_ROLE')}
            </Typography>
          </Stack>
        }
        <Stack direction="row" spacing={0} sx={Styles.SwitchContainer}>
          <Switch
            checked={isAdmin}
            onClick={() => setIsAdmin(!isAdmin)}
            sx={Styles.AdminSwitch}
          />
          <Box sx={Styles.SwitchLabelContainer}>
            <Typography variant="subtitle300">
              {t('USER_MANAGER')}
            </Typography>
            <Typography variant="body200">
              {t('CAN_MANAGE_USERS')}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box sx={Styles.EmailEntryButtonsContainer}>
        <Button
          variant="outlined"
          sx={Styles.CancelButton}
          onClick={() => setAddUserView(AddUserViews.EMAIL_ENTRY)}
          data-testid="back-button"
        >
          {t('BACK')}
        </Button>
        <LoadingButton
          sx={Styles.EmailEntryButton}
          loading={isLoading}
          textResourceKey="ADD"
          handleClick={handleSubmission}
        />
      </Box>
	</>
  );
}