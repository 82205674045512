import { Dispatch, SetStateAction, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EmailEntry, UserInformation } from 'features/account-settings';
import { useTranslation } from 'react-i18next';
import * as Styles from 'features/account-settings/manage-users/styles';
import { BreadcrumbsComponent } from './BreadcrumbsComponent';

export enum AddUserViews {
  EMAIL_ENTRY = 'email-entry',
  USER_INFORMATION = 'user-information'
}

export interface AddUserProps {
  setView: Dispatch<SetStateAction<symbol>>;
}

export const AddUser = ({ setView }: AddUserProps) => {
  const [email, setEmail] = useState<string>('');
  const [addUserView, setAddUserView] = useState<AddUserViews>(AddUserViews.EMAIL_ENTRY);
  const { t } = useTranslation();

  const isEmailEntry = addUserView === AddUserViews.EMAIL_ENTRY;
  const isUserInformation = addUserView === AddUserViews.USER_INFORMATION;

  return (
	<Box>
	  <BreadcrumbsComponent setView={setView} view={t('ADD_USER')}/>
	  <Typography variant="heading200" sx={Styles.AddUserHeader}>
		{t('ADD_USER')}
	  </Typography>
	  {isEmailEntry &&
		<EmailEntry
		  email={email}
		  setEmail={setEmail}
		  setManageUsersView={setView}
		  setAddUserView={setAddUserView}
		/>
	  }
	  {isUserInformation && 
		<UserInformation
		  email={email}
		  setAddUserView={setAddUserView}
		  setManageUsersView={setView}
	  	/>
	  }
	</Box>
  );
}