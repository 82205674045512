import React from 'react';
import { FunctionComponent, MouseEvent, useMemo, useCallback } from 'react';
import { Button } from 'shared/components/elements/elements.components';
import { useTranslation } from 'react-i18next';
import { Modal } from 'shared/components/modal/modal.component';
import { ChangePasswordForm } from 'features/account-settings';
import { Input } from 'shared/components/form-fields/input.component';
import { FormError } from 'shared/components/formatters/form-error.formatter';
import { useUpdateOnFormChanges } from 'app/hooks/forms/use-update-on-form-changes.hooks';
import { useForceRefreshToken } from 'app/hooks/use-force-refresh-token.hook';
import { useOnFormSubmit } from 'app/hooks/forms/use-on-form-submit.hook';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { useSelector } from 'app/hooks/store/use-selector.hook';

export const CHANGE_PASSWORD_MODAL = 'change-password-modal';

interface OwnProps {
  onSubmit: (request: UserPatchRequest, onSuccess?: () => any, onFailure?: (err: APIErrorResponse) => any) => void;
  onCancel: (e: MouseEvent<HTMLButtonElement>) => any;
}

export const ChangePasswordModal: FunctionComponent<OwnProps> = (props) => {
  const [refreshToken, forceRefresh] = useForceRefreshToken();
  const username = useSelector(state => state.auth.user?.username);
  const { t } = useTranslation();

  const form = useMemo(() => new ChangePasswordForm(username), [refreshToken, username]);

  const onAPIError = useCallback((err: APIErrorResponse) => {
    if (err instanceof APIErrorResponse && err.includes('E1030108C2A5')) {
      form.setServerErrors(err.errors.map(error => ({failureReason: Number(error.code.shortCode), propertyPath: null, propertyName: null} as any)));
    }
  }, [form]);

  const onSubmit = useCallback((request: UserPatchRequest) => {
    props.onSubmit(request, null, onAPIError);
  }, [props.onSubmit, onAPIError]);

  useUpdateOnFormChanges(form);

  const onFormSubmit = useOnFormSubmit(form, f => f.toPasswordUpdateRequest(), onSubmit);

  return (
    <form id="change-password-form" onSubmit={onFormSubmit}>
      <Modal
        name={CHANGE_PASSWORD_MODAL}
        onHide={forceRefresh}
        header="CHANGE_PASSWORD"
        children={() =>
          !form
            ? null
            : <React.Fragment>
                <p className="text-muted">{t("YOUR_PASSWORD_ELLIPSES")}</p>
                <ul className="text-muted">
                  <li>{t("MUST_BE_BETWEEN_6_AND_12_CHARACTERS")}</li>
                  <li>{t("MUST_CONTAIN_AT_LEAST_ONE_1_LETTER_AND_ONE_1_NUMBE")}</li>
                  <li>{t("MUST_NOT_CONTAIN_YOUR_USERNAME")}</li>
                  <li>{t("IT_IS_CASE_SENSITIVE")}</li>
                </ul>
                <Input
                  label="CURRENT_PASSWORD"
                  id="change-password-modal_current-password"
                  className="js-current-password"
                  type="password"
                  name="password"
                  field={form.get('password')}
                  renderErrors={field =>
                    <React.Fragment>
                      <FormError resource="CURRENT_PASSWORD_IS_REQUIRED" display={field.hasError('required')}/>
                      <FormError resource="CURRENT_PASSWORD_IS_INCORRECT" display={field.hasError('invalidPassword')}/>
                    </React.Fragment>
                  }
                />
                <Input
                  label="NEW_PASSWORD"
                  id="change-password-modal_new-password"
                  type="password"
                  className="js-new-password"
                  field={form.get('newPassword')}
                  name="newPassword"
                  renderErrors={field =>
                    <React.Fragment>
                      <FormError resource="NEW_PASSWORD_IS_REQUIRED" display={field.hasError('required')}/>
                      <FormError resource="MUST_BE_BETWEEN_6_AND_12_CHARACTERS" display={field.hasError('minLength') || field.hasError('maxLength')}/>
                      <FormError resource="MUST_CONTAIN_AT_LEAST_ONE_1_LETTER_AND_ONE_1_NUMBE"
                                 display={field.hasError('oneLetter') || field.hasError('oneNumber')}/>
                      <FormError resource="MUST_NOT_CONTAIN_YOUR_USERNAME" display={field.hasError('mustNotContain')}/>
                    </React.Fragment>
                  }
                />
                <Input
                  label="CONFIRM_NEW_PASSWORD"
                  id="change-password-modal_confirm-password"
                  type="password"
                  name="confirmPassword"
                  className="js-confirm-password"
                  field={form.get('confirmPassword')}
                  renderErrors={field =>
                    <React.Fragment>
                      <FormError resource="PLEASE_CONFIRM_NEW_PASSWORD" display={field.hasError('required')}/>
                      <FormError resource="PASSWORDS_DO_NOT_MATCH" display={field.hasError('mustMatch')}/>
                    </React.Fragment>
                  }
                />
              </React.Fragment>
        }

        footer={() =>
          <React.Fragment>
            <Button id="change-password-modal_cancel-button" btnLink onClick={props.onCancel} resource="CANCEL"/>
            <Button id="change-password-modal_save-button" btnPrimary type="submit" resource="SAVE"/>
          </React.Fragment>
        }
      />
    </form>
  );
};
